<template>
  <div>
    <base-popup
      :show="!!error"
      title="Fout/waarschuwing"
      awesomeicon="fas fa-exclamation-triangle"
      @close="handleErrorAndClose"
    >
      <p class="error">{{ error }}</p>
    </base-popup>
    <base-popup
      :show="registered"
      title="Je persoonlijke gegevens werden geregistreerd"
      @close="closePersonalInformation"
    >
      <p>
        Je persoonlijke gegevens werden geregistreerd en doorgestuurd naar de
        verantwoordelijke van het ledenbeheer.
      </p>
    </base-popup>
    <section>
      <base-card>
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div v-else>
          <h3>Mijn persoonlijke gegevens</h3>
          <hr />
          <base-tabs
            :tabList="tabList"
            :awesomeIconList="tabIconList"
            :tabCaptionType="tabCaptionType"
            fontsize="small"
            bold="false"
          >
            <template v-slot:tabPanel-1>
              <div class="boxed">
                <div class="inputs">
                  <div class="inputlabel">Voornaam</div>
                  <div class="inputfield">
                    <input
                      id="firstname"
                      v-model.trim="personalInformation.firstName"
                    />
                  </div>
                </div>
                <div class="inputs">
                  <div class="inputlabel">Naam</div>
                  <div class="inputfield">
                    <input
                      class="inputfullwidth"
                      id="lastname"
                      v-model.trim="personalInformation.lastName"
                    />
                  </div>
                </div>
                <div class="inputs">
                  <div class="inputlabel">Geboortedatum</div>
                  <div class="inputfield">
                    <input
                      id="birthdate"
                      type="date"
                      v-model.trim="personalInformation.birthdate"
                    />
                  </div>
                </div>
                <hr />
                <div class="inputs">
                  <div class="inputlabel">Leeftijdscategorie</div>
                  <div class="inputfield">
                    <input
                      class="inputfullwidth"
                      id="agecategory"
                      v-model.trim="personalInformation.ageCategory"
                      disabled
                    />
                  </div>
                </div>
                <div class="inputs">
                  <div class="inputlabel">Aansluitingsnr</div>
                  <div class="inputfield">
                    <input
                      id="association"
                      v-model.trim="personalInformation.associationId"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:tabPanel-2>
              <div class="boxed">
                <div class="inputs">
                  <div class="inputlabel">Adres</div>
                  <div class="inputfield">
                    <input
                      class="inputfullwidth"
                      id="address"
                      v-model.trim="personalInformation.address"
                    />
                  </div>
                </div>
                <div class="inputs">
                  <div class="inputlabel">Postcode</div>
                  <div class="inputfield">
                    <input id="zip" v-model.trim="personalInformation.zip" />
                  </div>
                </div>
                <div class="inputs">
                  <div class="inputlabel">Gemeente</div>
                  <div class="inputfield">
                    <input id="city" v-model.trim="personalInformation.city" />
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:tabPanel-3>
              <div class="boxed">
                <div class="inputs">
                  <div class="inputlabel">Telefoon</div>
                  <div class="inputfield">
                    <input
                      id="telephone"
                      v-model.trim="personalInformation.telephone"
                    />
                  </div>
                </div>
                <div class="inputs">
                  <div class="inputlabel">GSM</div>
                  <div class="inputfield">
                    <input
                      id="mobile"
                      v-model.trim="personalInformation.mobile"
                    />
                  </div>
                </div>
                <div class="inputs">
                  <div class="inputlabel">E-mailadres</div>
                  <div class="inputfield">
                    <input
                      type="email"
                      class="inputfullwidth"
                      id="email"
                      v-model.trim="personalInformation.email"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:tabPanel-4>
              <div class="boxed">
                <div class="inputs">
                  <div class="inputlabel">IBAN-rekening</div>
                  <div class="inputfield">
                    <input
                      id="ibanaccount"
                      v-model.trim="personalInformation.ibanAccount"
                    />
                  </div>
                </div>
                <div class="inputs">
                  <div class="inputlabel">Uw verrichtingen</div>
                  <div
                    class="financialinfo"
                    v-html="personalInformation.financialInformation"
                  ></div>
                </div>
              </div>
            </template>
            <template v-slot:tabPanel-5>
              <div class="boxed contributionforms">
                <table cellspacing="0" cellpadding="0" border="0" width="740">
                  <tr>
                    <td width="500px"><strong>Formulier</strong></td>
                    <td width="180px" style="text-align:right">
                      <strong>Bedrag</strong>
                    </td>
                    <td width="60px"></td>
                  </tr>
                  <tr
                    v-for="availableContributionForm in availableContributionForms"
                    :key="availableContributionForm.id"
                  >
                    <td
                      width="500px"
                      v-html="availableContributionForm.description"
                    ></td>
                    <td
                      width="180px"
                      v-html="availableContributionForm.amount"
                      style="text-align:right"
                    ></td>
                    <td width="60px" style="text-align:center">
                      <div
                        @click="
                          downloadContributionForm(availableContributionForm.id)
                        "
                      >
                        <i class="fas fa-download"></i>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </template>
            <template v-slot:tabPanel-6>
              <div class="boxed">
                <div class="inputs">
                  <div class="inputlabel">Voornaam</div>
                  <div class="inputfield">
                    <input
                      id="motherfirstname"
                      v-model.trim="personalInformation.motherFirstName"
                    />
                  </div>
                </div>
                <div class="inputs">
                  <div class="inputlabel">Naam</div>
                  <div class="inputfield">
                    <input
                      class="inputfullwidth"
                      id="motherlastname"
                      v-model.trim="personalInformation.motherLastName"
                    />
                  </div>
                </div>
                <div class="inputs">
                  <div class="inputlabel">Telefoon</div>
                  <div class="inputfield">
                    <input
                      id="mothertelephone"
                      v-model.trim="personalInformation.motherTelephone"
                    />
                  </div>
                </div>
                <div class="inputs">
                  <div class="inputlabel">GSM</div>
                  <div class="inputfield">
                    <input
                      id="mothermobile"
                      v-model.trim="personalInformation.motherMobile"
                    />
                  </div>
                </div>
                <div class="inputs">
                  <div class="inputlabel">E-mailadres</div>
                  <div class="inputfield">
                    <input
                      class="inputfullwidth"
                      type="email"
                      id="motheremail"
                      v-model.trim="personalInformation.motherEmail"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:tabPanel-7>
              <div class="boxed">
                <div class="inputs">
                  <div class="inputlabel">Voornaam</div>
                  <div class="inputfield">
                    <input
                      id="fatherfirstname"
                      v-model.trim="personalInformation.fatherFirstName"
                    />
                  </div>
                </div>
                <div class="inputs">
                  <div class="inputlabel">Naam</div>
                  <div class="inputfield">
                    <input
                      class="inputfullwidth"
                      id="fatherlastname"
                      v-model.trim="personalInformation.fatherLastName"
                    />
                  </div>
                </div>
                <div class="inputs">
                  <div class="inputlabel">Telefoon</div>
                  <div class="inputfield">
                    <input
                      id="fathertelephone"
                      v-model.trim="personalInformation.fatherTelephone"
                    />
                  </div>
                </div>
                <div class="inputs">
                  <div class="inputlabel">GSM</div>
                  <div class="inputfield">
                    <input
                      id="fathermobile"
                      v-model.trim="personalInformation.fatherMobile"
                    />
                  </div>
                </div>
                <div class="inputs">
                  <div class="inputlabel">E-mailadres</div>
                  <div class="inputfield">
                    <input
                      class="inputfullwidth"
                      type="email"
                      id="fatheremail"
                      v-model.trim="personalInformation.fatherEmail"
                    />
                  </div>
                </div>
              </div>
            </template>
          </base-tabs>
          <hr />
          <div class="buttons">
            <base-button @click="setPersonalInformation" mode="smallbold"
              >Mijn persoonlijke gegevens opslaan</base-button
            >
            <base-button @click="closePersonalInformation" mode="smallbold"
              >Sluit</base-button
            >
          </div>
        </div>
      </base-card>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      isLoading: false,
      personalInformation: {},
      error: null,
      registered: false
    };
  },
  computed: {
    tabList() {
      let tabs = ['Naam', 'Adres', 'Communicatie', 'Financieel'];

      const hasAvailableContributionForms = this.$store.getters[
        'hasAvailableContributionForms'
      ];
      if (hasAvailableContributionForms) {
        tabs.push('Mijn formulieren');
      }

      if (this.personalInformation.displayParentInformation) {
        tabs.push['Mijn mama'];
        tabs.push['Mijn papa'];
      }

      return tabs;
    },
    tabIconList() {
      let icons = [
        'fas fa-user',
        'fas fa-map-marker',
        'far fa-comments',
        'fas fa-euro-sign'
      ];

      const hasAvailableContributionForms = this.$store.getters[
        'hasAvailableContributionForms'
      ];
      if (hasAvailableContributionForms) {
        icons.push('far fa-file-alt');
      }

      if (this.personalInformation.displayParentInformation) {
        icons.push['fas fa-user'];
        icons.push['fas fa-user'];
      }

      return icons;
    },
    tabCaptionType() {
      if (window.innerWidth < 540) {
        return 'icons';
      }

      return 'iconsandtext';
    },
    availableContributionForms() {
      return this.$store.getters['availableContributionForms'];
    }
  },
  methods: {
    async loadPersonalInformation() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('loadPersonalInformation', {}).then(() => {
          this.personalInformation = this.$store.getters['personalInformation'];

          // console.log(this.personalInformation);
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van je persoonlijke gegevens!';
      }

      this.isLoading = false;

      // Close navigation
      if (this.$store.getters.isNavOpen) {
        this.$store.dispatch('toggleNav');
      }
    },
    async setPersonalInformation() {
      try {
        await this.$store
          .dispatch('setPersonalInformation', this.personalInformation)
          .then(() => {
            this.registered = true;
          });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het doorvoeren van je persoonlijke gegevens!';
        // console.error(this.error);
      }
    },
    closePersonalInformation() {
      this.$router.replace('/home');
    },
    async downloadContributionForm(id) {
      try {
        await this.$store.dispatch('getContributionFormURL', id).then(() => {
          const URL = this.$store.getters['contributionFormURL'];
          console.log(URL);
          // this.$router.push(URL);
          window.open(URL);
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het opvragen van de downloadlink!';
      }
    },
    handleError() {
      this.error = null;
    },
    handleErrorAndClose() {
      this.error = null;
    }
  },
  created() {
    this.loadPersonalInformation();
  }
};
</script>

<style scoped>
.buttons {
  margin-top: 10px;
}

.inputs {
  width: 100%;
  display: flex;
  margin-bottom: 5px;
}

.inputlabel {
  width: 140px;
  font-size: 14px;
  font-weight: bold;
  padding-top: 5px;
}

.inputfield {
  width: 100%;
  padding-left: 10px;
}
.inputfullwidth {
  width: 98%;
}
.boxed {
  width: 100%;
  overflow: scroll;
  height: 270px;
  margin-left: 5px;
}

.financialinfo {
  padding-left: 10px;
  width: 98%;
  font-size: 12px;
}

.contributionforms {
  font-size: 16px;
}
</style>
