<template>
  <form @submit.prevent="closeForm">
    <div class="form-control">
      <label for="remarks">Opmerking(en)</label>
      <textarea
        id="remarks"
        v-model.trim="remarks"
        rows="3"
        :disabled="!memberCanDrive"
      />
    </div>
    <div>
      <base-button @click="submitForm" v-if="memberCanDrive" mode="smallbold">{{
        driveText1
      }}</base-button>
      <base-button @click="closeForm" mode="smallbold">Sluit</base-button>
    </div>
  </form>
</template>

<script>
export default {
  props: ['id', 'meetingIsPast', 'driver_id', 'driver_remarks'],
  emits: ['save-driver', 'close-driver'],
  data() {
    return {
      remarks: ''
    };
  },
  computed: {
    memberCanDrive() {
      const currentMember = this.$store.getters['memberId'];
      // console.log(currentMember);
      // console.log(this.driver_id);
      if (
        currentMember !== 0 &&
        (currentMember == this.driver_id ||
          this.driver_id == null ||
          this.driver_id == '0')
      ) {
        return true;
      } else {
        return false;
      }
    },
    driveText1() {
      console.log(this.meetingIsPast);
      if (this.meetingIsPast) {
        return 'Ja, ik heb gereden';
      } else {
        return 'Ja, ik zal rijden';
      }
    }
  },
  methods: {
    closeForm() {
      this.$emit('close-driver');
    },
    submitForm() {
      const currentMember = this.$store.getters['memberId'];
      const formData = {
        driverId: currentMember,
        driverRemarks: this.remarks
      };

      this.$emit('save-driver', formData);
    }
  },
  mounted() {
    // const currentMember = this.$store.getters['memberId'];
    this.remarks = this.driver_remarks;
  }
};
</script>

<style scoped>
.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input,
textarea {
  display: block;
  width: 100%;
  border: 1px solid var(--var-edit-bordercolor);
  font: inherit;
}

input:focus,
textarea:focus {
  background-color: var(--var-edit-color-focused);
  outline: none;
  border-color: var(--var-edit-bordercolor-focused);
}

.invalid label {
  color: red;
}

.invalid input,
.invalid textarea {
  border: 1px solid red;
}
</style>
