<template>
  <div>
    <base-popup
      :show="!!error"
      title="Fout/waarschuwing"
      awesomeicon="fas fa-exclamation-triangle"
      @close="handleErrorAndClose"
    >
      <p class="error">{{ error }}</p>
    </base-popup>
    <base-dialog :show="confirmationFormIsVisible" :fixed="true">
      <template v-slot:header>
        <h2>
          <i class="far fa-handshake"></i>
          Geef aan of je kan deelnemen
        </h2>
      </template>
      <template v-slot:default>
        <team-confirmation-form
          :id="currentTeamConfirmation.teamCalendarId"
          :confirmationoption="currentTeamConfirmation.confirmationOption"
          :confirmationremarks="currentTeamConfirmation.remarks"
          :candrive="currentTeamConfirmation.canDrive"
          :ishome="currentTeamConfirmation.isHome == 1"
          @close-confirmation="closeConfirmationForm"
          @save-confirmation="saveConfirmation"
        ></team-confirmation-form>
        <div v-if="isUpdating">
          <base-spinner></base-spinner>
        </div>
      </template>
    </base-dialog>
    <base-dialog :show="compositionFormIsVisible" :fixed="true">
      <template v-slot:header>
        <h2>
          <i class="fas fa-user-edit"></i>
          {{ selectedTeam }} -
          {{ currentTeamComposition.weekDescription }}
        </h2>
      </template>
      <template v-slot:default>
        <composition-form
          :id="currentTeamComposition.id"
          :team="currentTeamComposition.team"
          :nrofplayersperteam="currentTeamComposition.nrOfPlayersPerTeam"
          :startplayerindex="currentTeamComposition.startPlayerIndex"
          :availableteamplayers="availableTeamPlayers"
          :teamplayers="currentTeamComposition.members"
          :teamreserves="currentTeamComposition.reserves"
          :confirmed="currentTeamComposition.confirmed"
          :denied="currentTeamComposition.denied"
          :remarks="currentTeamComposition.remarks"
          :addplayer="addPlayerToComposition"
          @close-composition="closeCompositionForm"
          @save-composition="saveComposition"
        ></composition-form>
      </template>
    </base-dialog>
    <section>
      <base-card>
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div v-else>
          <h5>Ploegen</h5>
          <team-filter
            :defaultteam="selectedTeam"
            @change-team="setSelectedTeam"
          ></team-filter>
          <hr />

          <v-card v-if="thereAreResults">
            <v-tabs v-model="tab" bg-color="#d0ebff" v-if="thereAreResults">
              <v-tab value="setup"
                ><v-icon icon="mdi-account-check-outline"></v-icon>
                <div v-if="!isMobile">
                  Confirmaties &<br />opstellingen
                </div></v-tab
              >
              <v-tab value="calendar"
                ><v-icon icon="mdi-calendar-month-outline"></v-icon>
                <div v-if="!isMobile">Kalender</div></v-tab
              >
              <v-tab value="ranking"
                ><v-icon icon="mdi-chart-bar"></v-icon>
                <div v-if="!isMobile">Rangschikking</div></v-tab
              >
              <v-tab value="results"
                ><v-icon icon="mdi-percent"></v-icon>
                <div v-if="!isMobile">Weekprestaties</div></v-tab
              >
              <v-tab value="players"
                ><v-icon icon="mdi-account-group"></v-icon>
                <div v-if="!isMobile">Spelers</div></v-tab
              >
            </v-tabs>

            <v-card-text>
              <v-window v-model="tab">
                <v-window-item value="setup">
                  <div class="boxed">
                    <div v-if="hasTeamConfirmations">
                      <table
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                        width="740"
                      >
                        <tr>
                          <td width="20px"></td>
                          <td width="30px" class="columntitle">
                            <b>Wk</b>
                          </td>
                          <td width="250px">
                            <div class="columntitle">
                              <b>Ontmoeting</b
                              ><span class="form-check form-switch">
                                <label
                                  for="pastmeetings"
                                  class="checkboxtext"
                                  v-if="hasFutureConfirmations"
                                  >Voorbije ontmoetingen</label
                                >
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="pastmeetings"
                                  :checked="showPastMeetings"
                                  @change="setShowPastMeetings"
                                  v-if="hasFutureConfirmations"
                                />
                              </span>
                            </div>
                          </td>
                          <td width="250px">
                            <div class="columntitle">
                              <b>Speler</b
                              ><span class="form-check form-switch">
                                <label for="onlymymeetings" class="checkboxtext"
                                  >Enkel mijn ontmoetingen</label
                                >
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="onlymymeetings"
                                  :checked="showMyMeetings"
                                  @change="setShowMyMeetings"
                                />
                              </span>
                            </div>
                          </td>
                          <td width="20px"></td>
                          <td width="20px"></td>
                          <td width="20px"></td>
                        </tr>
                        <tr
                          v-for="teamconfirmation in teamConfirmations"
                          :key="teamconfirmation.id"
                        >
                          <td
                            width="20px"
                            :class="wonClass(teamconfirmation.won)"
                            v-if="teamconfirmation.isPast == 1"
                          >
                            <div v-if="teamconfirmation.won == 1">
                              <i class="far fa-smile"></i>
                            </div>
                            <div v-if="teamconfirmation.won == -1">
                              <i class="far fa-frown"></i>
                            </div>
                            <div v-if="teamconfirmation.won == 0">
                              <i class="far fa-meh"></i>
                            </div>
                          </td>

                          <td width="20px" v-else>
                            <div
                              v-if="canCompose(teamconfirmation.id)"
                              @click="
                                showCompositionForm(teamconfirmation.id, 0)
                              "
                              class="compositioncolumn"
                            >
                              <i class="fas fa-user-edit"></i>
                            </div>
                          </td>
                          <td width="30px" style="text-align: center">
                            {{ teamconfirmation.week }}
                          </td>
                          <td width="250px">
                            <div
                              v-if="
                                showMeeting(
                                  teamconfirmation.id,
                                  teamconfirmation.meeting
                                )
                              "
                              v-html="meetingInformation(teamconfirmation)"
                            ></div>
                            <div
                              v-if="
                                showMeeting(
                                  teamconfirmation.id,
                                  teamconfirmation.meeting
                                ) &&
                                teamconfirmation.isPast == 0 &&
                                teamconfirmation.status != 0
                              "
                              class="meetingstatus"
                            >
                              {{ teamconfirmation.statusInformation }}
                            </div>
                          </td>
                          <td width="250px">
                            <div
                              :class="confirmationNameClass(teamconfirmation)"
                              v-html="confirmationNameInfo(teamconfirmation)"
                            ></div>
                            <div
                              v-if="teamconfirmation.remarks"
                              class="playerremarks"
                            >
                              <i class="fas fa-exclamation-triangle"></i>&nbsp;
                              {{ teamconfirmation.remarks }}
                            </div>
                          </td>
                          <td width="20px">
                            <div
                              :class="confirmClass(teamconfirmation)"
                              v-html="confirmIcon(teamconfirmation)"
                              @click="showConfirmationForm(teamconfirmation)"
                            ></div>
                          </td>
                          <td width="20px">
                            <div
                              v-if="
                                teamconfirmation.isPast == 0 &&
                                teamconfirmation.hasBeenSetup == 1 &&
                                canCompose(teamconfirmation.id)
                              "
                              @click="
                                showCompositionForm(teamconfirmation.id, 0)
                              "
                              class="compositioncolumn issetup"
                            >
                              <i class="fas fa-users"></i>
                            </div>
                            <div
                              v-if="
                                teamconfirmation.isPast == 0 &&
                                teamconfirmation.hasBeenSetup == 0 &&
                                canCompose(teamconfirmation.id)
                              "
                              @click="
                                showCompositionForm(
                                  teamconfirmation.id,
                                  teamconfirmation.playerId
                                )
                              "
                              class="compositioncolumn asktosetup"
                            >
                              <i class="fas fa-plus"></i>
                            </div>
                            <div
                              v-if="
                                teamconfirmation.isPast == 0 &&
                                teamconfirmation.hasBeenSetup == 1 &&
                                !canCompose(teamconfirmation.id)
                              "
                              class="issetup"
                            >
                              <i class="fas fa-users"></i>
                            </div>
                          </td>
                          <td width="20px">
                            <div
                              v-if="
                                teamconfirmation.isPast == 0 &&
                                teamconfirmation.canDrive == 1
                              "
                            >
                              <i class="fas fa-car-alt"></i>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td width="20px"></td>
                          <td width="30px"></td>
                          <td width="250px"></td>
                          <td width="250px"></td>
                          <td width="20px"></td>
                          <td width="20px"></td>
                          <td width="20px"></td>
                        </tr>
                      </table>
                    </div>
                    <div v-else>
                      <div>
                        Er werden nog geen confirmaties, annulaties of
                        opstellingen geregistreerd.
                      </div>
                    </div>
                  </div>
                </v-window-item>
                <v-window-item value="calendar">
                  <div class="boxed">
                    <div v-if="hasCalendars">
                      <table
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                        width="740"
                      >
                        <tr>
                          <td width="30px"></td>
                          <td width="60px"><b>Nummer</b></td>
                          <td width="115px"><b>Datum</b></td>
                          <td width="110px"><b>Thuis</b></td>
                          <td width="110px"><b>Uit</b></td>
                          <td width="40px" style="text-align: center">
                            <b>Score</b>
                          </td>
                          <td width="20px"></td>
                        </tr>
                        <tr
                          v-for="calendar in selectedCalendars"
                          :key="calendar.id"
                        >
                          <td width="30px" style="text-align: center">
                            {{ calendar.week }}
                          </td>
                          <td width="60px">
                            <a :href="calendar.matchUrl" target="_blank">{{
                              calendar.playId
                            }}</a>
                          </td>
                          <td width="115px">
                            {{ calendar.playDate }}
                          </td>
                          <td
                            width="110px"
                            :class="homeClass(calendar.homeTeamCode)"
                          >
                            {{ calendar.homeTeam }}
                          </td>
                          <td
                            width="110px"
                            :class="outClass(calendar.outTeamCode)"
                          >
                            {{ calendar.outTeam }}
                          </td>
                          <td width="40px" style="text-align: center">
                            {{ calendar.score }}
                          </td>
                          <td width="20px" :class="wonClass(calendar.won)">
                            <div v-if="calendar.won == 1">
                              <i class="far fa-smile"></i>
                            </div>
                            <div v-if="calendar.won == -1">
                              <i class="far fa-frown"></i>
                            </div>
                            <div v-if="calendar.won == 0">
                              <i class="far fa-meh"></i>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div v-else>
                      <div>
                        Er is geen standaard ploegsamenstelling ingebracht.
                      </div>
                    </div>
                  </div>
                </v-window-item>
                <v-window-item value="ranking">
                  <div class="boxed">
                    <div v-if="hasRankings">
                      <table
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                        width="740"
                      >
                        <tr>
                          <td width="50px"></td>
                          <td width="400px"><b>Ploeg</b></td>
                          <td width="50px" style="text-align: center">
                            <b>P</b>
                          </td>
                          <td width="50px" style="text-align: center">
                            <b>W</b>
                          </td>
                          <td width="50px" style="text-align: center">
                            <b>L</b>
                          </td>
                          <td width="50px" style="text-align: center">
                            <b>=</b>
                          </td>
                          <td width="50px" style="text-align: center">
                            <b>Ptn</b>
                          </td>
                        </tr>
                        <tr
                          v-for="ranking in selectedRankings"
                          :key="ranking.id"
                          :class="teamClass(ranking.club)"
                        >
                          <td width="50px" style="text-align: center">
                            {{ ranking.position }}
                          </td>
                          <td width="400px">
                            {{ ranking.teamDescription }}
                          </td>
                          <td width="50px" style="text-align: center">
                            {{ ranking.gamesPlayed }}
                          </td>
                          <td width="50px" style="text-align: center">
                            {{ ranking.gamesWon }}
                          </td>
                          <td width="50px" style="text-align: center">
                            {{ ranking.gamesLost }}
                          </td>
                          <td width="50px" style="text-align: center">
                            {{ ranking.gamesDraw }}
                          </td>
                          <td width="50px" style="text-align: center">
                            {{ ranking.points }}
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div v-else>
                      <div>
                        Er is geen rangschikking beschikbaar voor deze ploeg
                      </div>
                    </div>
                  </div>
                </v-window-item>

                <v-window-item value="results">
                  <div class="boxed">
                    <div v-if="hasWeekResults">
                      <table
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                        width="740"
                      >
                        <tr>
                          <td width="150px"></td>
                          <td
                            width="40px"
                            style="text-align: center"
                            v-if="isTableTennis"
                          >
                            <b>%</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 1"
                          >
                            <b>1</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 2"
                          >
                            <b>2</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 3"
                          >
                            <b>3</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 4"
                          >
                            <b>4</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 5"
                          >
                            <b>5</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 6"
                          >
                            <b>6</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 7"
                          >
                            <b>7</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 8"
                          >
                            <b>8</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 9"
                          >
                            <b>9</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 10"
                          >
                            <b>10</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 11"
                          >
                            <b>11</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 12"
                          >
                            <b>12</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 13"
                          >
                            <b>13</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 14"
                          >
                            <b>14</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 15"
                          >
                            <b>15</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 16"
                          >
                            <b>16</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 17"
                          >
                            <b>17</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 18"
                          >
                            <b>18</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 19"
                          >
                            <b>19</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 20"
                          >
                            <b>20</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 21"
                          >
                            <b>21</b>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 22"
                          >
                            <b>22</b>
                          </td>
                          <td
                            width="30px"
                            style="text-align: center"
                            v-if="isTableTennis"
                          >
                            <b>#</b>
                          </td>
                          <td
                            width="35px"
                            style="text-align: center"
                            v-if="isTableTennis"
                          ></td>
                        </tr>
                        <tr
                          v-for="weekResult in selectedWeekResults"
                          :key="weekResult.id"
                        >
                          <td width="100px" style="text-align: left">
                            {{ weekResult.memberName }}
                          </td>
                          <td
                            width="40px"
                            style="text-align: center"
                            v-if="isTableTennis"
                          >
                            {{ weekResult.percentageString }}
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 1"
                          >
                            <div v-html="weekResult.w01"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 2"
                          >
                            <div v-html="weekResult.w02"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 3"
                          >
                            <div v-html="weekResult.w03"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 4"
                          >
                            <div v-html="weekResult.w04"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 5"
                          >
                            <div v-html="weekResult.w05"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 6"
                          >
                            <div v-html="weekResult.w06"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 7"
                          >
                            <div v-html="weekResult.w07"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 8"
                          >
                            <div v-html="weekResult.w08"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 9"
                          >
                            <div v-html="weekResult.w09"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 10"
                          >
                            <div v-html="weekResult.w10"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 11"
                          >
                            <div v-html="weekResult.w11"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 12"
                          >
                            <div v-html="weekResult.w12"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 13"
                          >
                            <div v-html="weekResult.w13"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 14"
                          >
                            <div v-html="weekResult.w14"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 15"
                          >
                            <div v-html="weekResult.w15"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 16"
                          >
                            <div v-html="weekResult.w16"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 17"
                          >
                            <div v-html="weekResult.w17"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 18"
                          >
                            <div v-html="weekResult.w18"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 19"
                          >
                            <div v-html="weekResult.w19"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 20"
                          >
                            <div v-html="weekResult.w20"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 21"
                          >
                            <div v-html="weekResult.w21"></div>
                          </td>
                          <td
                            width="15px"
                            style="text-align: center"
                            v-if="nrOfCalendarItems >= 22"
                          >
                            <div v-html="weekResult.w22"></div>
                          </td>
                          <td
                            width="30px"
                            style="text-align: center"
                            v-if="isTableTennis"
                          >
                            {{ weekResult.entries }}
                          </td>
                          <td
                            width="35px"
                            style="text-align: center"
                            v-if="isTableTennis"
                          >
                            {{ weekResult.saldo }}
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div v-else>
                      <div>Er zijn nog geen weekresultaten.</div>
                    </div>
                  </div>
                </v-window-item>
                <v-window-item value="players">
                  <div class="boxed">
                    <div v-if="hasTeamPlayers">
                      <table
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                        width="740"
                      >
                        <tr>
                          <td width="30px"></td>
                          <td width="150px"><b>Naam</b></td>
                          <td width="50px"><b>Kl</b></td>
                          <td width="110px"><b>Tel</b></td>
                          <td width="150px"><b>E-mail</b></td>
                        </tr>
                        <tr
                          v-for="teamplayer in selectedTeamPlayers"
                          :key="teamplayer.id"
                          :class="captainClass(teamplayer.captain)"
                        >
                          <td width="30px">
                            {{ teamplayer.rank }}
                          </td>
                          <td width="150px">
                            {{ teamplayer.memberName }}
                          </td>
                          <td width="50px">
                            {{ teamplayer.ranking }}
                          </td>
                          <td width="110px">
                            {{ teamplayer.telephone }}
                          </td>
                          <td width="150px">
                            <div v-html="teamplayer.email"></div>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div v-else>
                      <div>
                        Er is geen standaard ploegsamenstelling ingebracht.
                      </div>
                    </div>
                  </div>
                </v-window-item>
              </v-window>
            </v-card-text>
          </v-card>

          <div v-else>
            <p>
              <i class="fas fa-info-circle"></i>&nbsp;Er zijn nog geen
              overzichten en/of statistieken beschikbaar!
            </p>
          </div>
          <div class="buttons" v-if="false">
            <base-button @click="openFrenoy" mode="smallbold"
              >Open mijn resultaten op Frenoy</base-button
            >
          </div>
          <hr />
          <div>
            <div @click="sendIcalFileForTeam" class="ical">
              <i class="far fa-envelope"></i>&nbsp;Stuur me een iCal voor alle
              ontmoetingen van deze ploeg ({{ selectedTeam }})
            </div>
          </div>
        </div>
      </base-card>
    </section>
  </div>
</template>

<script>
import TeamFilter from '../../components/teams/TeamFilter.vue';
import CompositionForm from '../../components/teams/TeamComposition.vue';
import TeamConfirmationForm from '../../components/teams/TeamConfirmationForm.vue';

export default {
  components: {
    TeamFilter,
    CompositionForm,
    TeamConfirmationForm,
  },
  data() {
    return {
      isLoading: false,
      isUpdating: false,
      tab: null,
      compositionsNeedRelease: false,
      teams: [],
      selectedTeam: '',
      confirmationFormIsVisible: false,
      compositionFormIsVisible: false,
      addPlayerToComposition: 0,
      currentTeamComposition: null,
      currentTeamConfirmation: null,
      availableTeamPlayers: [],
      showPastMeetings: false,
      showMyMeetings: false,
      error: null,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 540;
    },
    isTableTennis() {
      return this.$store.getters['isTableTennis'];
    },
    tabList() {
      return [
        '<div>Confirmaties & opstellingen</div>',
        '<div>Kalender</div>',
        '<div>Rangschikking</div>',
        '<div>Weekprestaties</div>',
        '<div>Spelers</div>',
      ];
    },
    tabIconList() {
      return [
        'far fa-check-circle',
        'far fa-calendar-alt',
        'fas fa-chart-line',
        'fas fa-balance-scale-right',
        'far fa-address-card',
      ];
    },
    tabCaptionType() {
      if (window.innerWidth < 540) {
        return 'icons';
      }

      return 'iconsandtext';
    },
    thereAreResults() {
      const rankings = this.$store.getters['statistics/rankings'];
      const teamPlayers = this.$store.getters['statistics/teamPlayers'];
      const teamCalendars = this.$store.getters['statistics/teamCalendars'];
      const results = this.$store.getters['statistics/weekResults'];

      return (
        (rankings && rankings.length > 0) ||
        (teamPlayers && teamPlayers.length > 0) ||
        (teamCalendars && teamCalendars.length > 0) ||
        (results && results.length > 0)
      );
    },
    hasRankings() {
      const rankings = this.selectedRankings;

      return rankings && rankings.length > 0;
    },
    selectedRankings() {
      const rankings = this.$store.getters['statistics/rankings'];

      return rankings.filter((ranking) => {
        return this.selectedTeam && ranking.team == this.selectedTeam
          ? true
          : false;
      });
    },
    hasTeamPlayers() {
      const teamPlayers = this.selectedTeamPlayers;

      return teamPlayers && teamPlayers.length > 0;
    },
    selectedTeamPlayers() {
      const teamPlayers = this.$store.getters['statistics/teamPlayers'];

      return teamPlayers.filter((teamPlayer) => {
        return this.selectedTeam && teamPlayer.team == this.selectedTeam
          ? true
          : false;
      });
    },
    hasCalendars() {
      const calendars = this.selectedCalendars;

      return calendars && calendars.length > 0;
    },
    nrOfCalendarItems() {
      const calendars = this.selectedCalendars;
      return calendars.length;
    },
    selectedCalendars() {
      const teamCalendars = this.$store.getters['statistics/teamCalendars'];
      // console.log(teamCalendars);

      return teamCalendars.filter((teamCalendar) => {
        return this.selectedTeam && teamCalendar.team == this.selectedTeam
          ? true
          : false;
      });
    },
    hasTeamConfirmations() {
      const teamConfirmations =
        this.$store.getters['statistics/teamConfirmations'];

      // console.log(teamConfirmations);
      return teamConfirmations && teamConfirmations.length > 0;
    },
    hasFutureConfirmations() {
      const teamConfirmations =
        this.$store.getters['statistics/teamConfirmations'];

      return (
        teamConfirmations.filter((teamConfirmation) => {
          if (teamConfirmation.isPast == 1) {
            return false;
          }

          return true;
        }).length > 0
      );
    },
    teamConfirmations() {
      // If the underlying logic is modified, do check showMeeting method and vice-versa!
      const teamConfirmations =
        this.$store.getters['statistics/teamConfirmations'];

      const currentMember = this.$store.getters['memberId'];
      const hasFutureConfirmations = this.hasFutureConfirmations;
      const filteredTeamConfirmations = teamConfirmations.filter(
        (teamConfirmation) => {
          // Past meeting, no need to show the non participants
          // console.log(teamConfirmation.nrOfParticipations);
          if (
            teamConfirmation.isPast == 1 &&
            teamConfirmation.hasBeenSetup == 0
          ) {
            return false;
          }

          if (
            teamConfirmation.isTeamPlayer == 0 &&
            teamConfirmation.nrOfParticipations == 0
          ) {
            return false;
          }

          if (
            hasFutureConfirmations &&
            this.showPastMeetings == false &&
            teamConfirmation.isPast == 1
          ) {
            return false;
          }

          if (
            this.showMyMeetings == true &&
            teamConfirmation.playerId != currentMember
          ) {
            return false;
          }

          return true;
        }
      );

      // console.log(filteredTeamConfirmations);
      return filteredTeamConfirmations;
    },
    hasWeekResults() {
      const weekResults = this.selectedWeekResults;

      return weekResults && weekResults.length > 0;
    },
    selectedWeekResults() {
      const weekResults = this.$store.getters['statistics/weekResults'];
      console.log(weekResults);
      return weekResults.filter((weekResult) => {
        return this.selectedTeam && weekResult.team == this.selectedTeam
          ? true
          : false;
      });
    },
    teamClass() {
      const clubcode = this.$store.getters.clubCode;
      return (club) => {
        if (club == clubcode) {
          return 'club';
        } else {
          return '';
        }
      };
    },
    homeClass() {
      const clubcode = this.$store.getters.clubCode;
      return (club) => {
        if (club == clubcode) {
          return 'club';
        } else {
          return '';
        }
      };
    },
    outClass() {
      const clubcode = this.$store.getters.clubCode;
      return (club) => {
        if (club == clubcode) {
          return 'club';
        } else {
          return '';
        }
      };
    },
    wonClass() {
      return (won) => {
        if (won == '1') {
          return 'won';
        }

        if (won == '-1') {
          return 'lost';
        }

        if (won == '0') {
          return 'draw';
        }

        return '';
      };
    },
    captainClass() {
      return (captain) => {
        if (captain) {
          return 'captain';
        }

        return '';
      };
    },
  },
  methods: {
    async loadStatistics() {
      this.isLoading = true;

      const teams = this.$store.getters['teams/teams'];
      if (teams && teams.length > 0) {
        this.teams = teams;
      } else {
        try {
          await this.$store.dispatch('teams/loadTeams').then(() => {
            const teams = this.$store.getters['teams/teams'];
            this.teams = teams;
          });
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het ophalen van de ploegen!';
        }
      }

      // Get (first) team from the list that belongs to "myTeams"
      const myTeams = this.$store.getters['myTeams'];
      if (myTeams) {
        teams.forEach((team) => {
          if (myTeams.indexOf(team.team) >= 0) {
            // console.log('Set default team: ', team.team);
            this.setSelectedTeam(team.team);
          }
        });
      }

      try {
        await this.$store.dispatch('statistics/loadRankings', {}).then(() => {
          const rankings = this.$store.getters['statistics/rankings'];
          if (this.selectedTeam == '' && rankings && rankings.length > 0) {
            this.setSelectedTeam(rankings[0].team);
          }
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het opvragen van de rangschikkingen!';
      }

      try {
        await this.$store
          .dispatch('statistics/loadTeamPlayers', {})
          .then(() => {
            const teamPlayers = this.$store.getters['statistics/teamPlayers'];
            if (
              this.selectedTeam == '' &&
              teamPlayers &&
              teamPlayers.length > 0
            ) {
              this.setSelectedTeam(teamPlayers[0].team);
            }
          });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het opvragen van de ploegsamenstellingen!';
      }

      try {
        await this.$store
          .dispatch('statistics/loadTeamCalendars', {})
          .then(() => {
            const teamCalendars =
              this.$store.getters['statistics/teamCalendars'];
            if (
              this.selectedTeam == '' &&
              teamCalendars &&
              teamCalendars.length > 0
            ) {
              this.setSelectedTeam(teamCalendars[0].team);
            }
          });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het opvragen van de ploegkalenders!';
      }

      try {
        await this.$store.dispatch('statistics/loadWeekResults', {});
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het opvragen van de weekoverzichten!';
      }

      this.isLoading = false;

      // Close navigation
      if (this.$store.getters.isNavOpen) {
        this.$store.dispatch('toggleNav');
      }
    },
    async getConfirmations() {
      // Get teamId
      let teamId = 0;
      this.teams.forEach((team) => {
        if (team.team == this.selectedTeam) {
          teamId = team.id;
        }
      });

      try {
        await this.$store.dispatch('statistics/loadTeamConfirmations', {
          teamId: teamId,
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het opvragen van de spelersconfirmaties!';
      }
    },
    async setSelectedTeam(selectedTeam) {
      this.selectedTeam = selectedTeam;
      this.getConfirmations();
    },
    openFrenoy() {
      const url = this.$store.getters.frenoyPublicUrl;
      window.open(url);
    },
    async sendIcalFileForTeam() {
      if (
        confirm(
          `Wens je e-mailbericht(en) met de (komende) afspraakdata voor de ploeg ${this.selectedTeam}?\n\n(Je kan deze afspraakdata dan eenvoudig aan je agenda toevoegen)`
        )
      ) {
        try {
          this.isLoading = true;

          // Get teamId
          let teamId = 0;
          const teams = this.$store.getters['teams/teams'];
          teams.forEach((team) => {
            if (team.team == this.selectedTeam) {
              teamId = team.id;
            }
          });

          // console.log(teamId);
          // Send iCalfiles
          await this.$store.dispatch('teams/sendIcalEventFile', {
            teamId: teamId,
          });
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het versturen van de gegevens!';
        }

        this.isLoading = false;
      }
    },
    handleError() {
      this.error = null;
    },
    handleErrorAndClose() {
      this.error = null;
    },
    showMeeting(id, meeting) {
      const teamConfirmations =
        this.$store.getters['statistics/teamConfirmations'];

      // If the underlying logic is modified, do check teamConfirmations function and vice-versa!
      // Check whether the previous meeting has the same description
      let pid = 0;
      const currentMember = this.$store.getters['memberId'];
      if (id > 0) {
        pid = id - 1;
        while (pid > 0) {
          if (
            teamConfirmations[pid].isPast == 1 &&
            teamConfirmations[pid].hasBeenSetup == 0
          ) {
            pid = pid - 1;
          } else if (
            teamConfirmations[pid].isTeamPlayer == 0 &&
            teamConfirmations[pid].nrOfParticipations == 0
          ) {
            pid = pid - 1;
          } else if (
            this.hasFutureConfirmations &&
            this.showPastMeetings == false &&
            teamConfirmations[pid].isPast == 1
          ) {
            pid = pid - 1;
          } else if (
            this.showMyMeetings == true &&
            teamConfirmations[pid].playerId != currentMember
          ) {
            pid = pid - 1;
          }

          break;
        }
      }

      // console.log(pid);
      // console.log(teamConfirmations[pid].meeting);

      return (
        this.showMyMeetings == true ||
        id == 0 ||
        teamConfirmations[pid].meeting != meeting
      );
    },
    meetingInformation(teamConfirmation) {
      let info = teamConfirmation.meeting;
      if (teamConfirmation.isPast == 0 && teamConfirmation.status != 0) {
        info = '<i class="fas fa-exclamation-triangle"></i>&nbsp;' + info;
      }

      if (teamConfirmation.meetingDate) {
        info =
          info +
          '<br><small><em>' +
          teamConfirmation.meetingDate +
          '</em></small>';
      }
      if (teamConfirmation.isPast && teamConfirmation.score) {
        info =
          info +
          '<br><strong><small>' +
          teamConfirmation.score +
          '</small></strong>';
      }

      return info;
    },
    setShowPastMeetings() {
      this.showPastMeetings = !this.showPastMeetings;
    },
    setShowMyMeetings() {
      this.showMyMeetings = !this.showMyMeetings;
    },
    canConfirm(id) {
      const teamConfirmations =
        this.$store.getters['statistics/teamConfirmations'];

      const currentMember = this.$store.getters['memberId'];
      if (currentMember != teamConfirmations[id].playerId) {
        return false;
      }

      if (
        teamConfirmations[id].isPast == 0 &&
        teamConfirmations[id].canPlay < 0
      ) {
        return true;
      }

      return false;
    },
    confirmationNameClass(teamConfirmation) {
      let c = 'playerisnoteamplayer';

      // console.log(teamConfirmation.isTeamPlayer);
      if (teamConfirmation.hasBeenSetup == 1) {
        c = 'playerissetup';
      } else if (
        teamConfirmation.isPast == 0 &&
        teamConfirmation.isTeamPlayer == 1
      ) {
        c = '';
      }

      return c;
    },
    confirmationNameInfo(teamConfirmation) {
      // console.log(teamConfirmation);
      let info = teamConfirmation.playerName;
      if (teamConfirmation.ranking) {
        info =
          info + '<small> <em>(' + teamConfirmation.ranking + ')</em></small>';
      }
      if (teamConfirmation.victoryCount) {
        info = info + '<small> - ' + teamConfirmation.victoryCount + '</small>';
      }
      // console.log(info);

      return info;
    },
    confirmClass(teamConfirmation) {
      let c = 'danger';
      const currentMember = this.$store.getters['memberId'];

      if (teamConfirmation.confirmationVariant) {
        c = teamConfirmation.confirmationVariant;
      } else {
        if (teamConfirmation.canPlay == 1) {
          c = 'success';
        } else if (
          teamConfirmation.isPast == 0 &&
          teamConfirmation.canPlay != 0 &&
          currentMember == teamConfirmation.playerId
        ) {
          c = 'asktoplay';
        }
      }

      if (this.compositionsNeedRelease != 0 && teamConfirmation.isPast == 0) {
        const canComposeAsCaptain = this.$store.getters['canComposeAsCaptain'];
        if (
          currentMember == teamConfirmation.playerId ||
          (canComposeAsCaptain &&
            teamConfirmation.captain &&
            teamConfirmation.captain == currentMember)
        ) {
          c = c + ' canconfirmcolumn';
        }
      }

      return c;
    },
    confirmIcon(teamConfirmation) {
      // A past meeting does no longer need to be confirmed, nor does it have to be visisble
      if (teamConfirmation.isPast == 1) {
        return '';
      }

      if (teamConfirmation.canPlay == 1) {
        return '<i class="fas fa-check"></i>';
      }

      if (teamConfirmation.canPlay == 0) {
        return '<i class="fas fa-ban"></i>';
      }

      if (this.compositionsNeedRelease == 0) {
        return '';
      }

      const currentMember = this.$store.getters['memberId'];
      if (currentMember == teamConfirmation.playerId) {
        return '<i class="far fa-question-circle"></i>';
      }

      return '';
    },
    async showConfirmationForm(teamConfirmation) {
      const currentMember = this.$store.getters['memberId'];
      const canComposeAsCaptain = this.$store.getters['canComposeAsCaptain'];
      if (teamConfirmation.isPast != 1) {
        try {
          if (
            currentMember == teamConfirmation.playerId ||
            (canComposeAsCaptain &&
              teamConfirmation.captain &&
              teamConfirmation.captain == currentMember)
          ) {
            const teamCompositionId = teamConfirmation.teamCalendarId;

            // Get/update current composition
            await this.$store.dispatch('teams/loadTeamCompositions', {
              id: teamCompositionId,
            });

            const teamCompositions =
              this.$store.getters['teams/teamCompositions'];
            this.currentTeamComposition = teamCompositions.find(
              (teamComposition) => teamComposition.id === teamCompositionId
            );

            if (this.currentTeamComposition) {
              // console.log(teamConfirmation);
              this.currentTeamConfirmation = teamConfirmation;
              // console.log(this.currentTeamComposition);
              this.confirmationFormIsVisible = true;
            }
          }
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het ophalen van de beschikbare spelers!';
        }
      }
    },
    closeConfirmationForm() {
      this.confirmationFormIsVisible = false;
    },
    async saveConfirmation(formData) {
      // console.log(formData);
      this.isUpdating = true;

      const payload = {
        id: formData.id,
        confirmationOption: formData.confirmationOption,
        remarks: formData.remarks,
        canDrive: formData.canDrive,
      };

      if (formData.participate == 1) {
        try {
          await this.$store.dispatch('teams/participateMeeting', payload);
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het aangeven dat je kan deelnemen!';
        }
      } else {
        try {
          // console.log('denyMeeting');
          await this.$store.dispatch('teams/denyMeeting', payload);
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het aangeven dat je niet kan deelnemen!';
        }
      }

      this.isUpdating = false;

      this.getConfirmations();
      this.confirmationFormIsVisible = false;
    },
    canCompose(id) {
      if (this.compositionsNeedRelease == 0) {
        return false;
      }

      const teamConfirmations =
        this.$store.getters['statistics/teamConfirmations'];

      if (teamConfirmations[id].isPast == 1) {
        return false;
      }

      const canComposeAnyTeam = this.$store.getters['canComposeAnyTeam'];
      if (canComposeAnyTeam === true) {
        return true;
      }

      if (teamConfirmations[id].captain && teamConfirmations[id].captain > 0) {
        const canComposeAsCaptain = this.$store.getters['canComposeAsCaptain'];
        if (canComposeAsCaptain === true) {
          const currentMember = this.$store.getters['memberId'];
          return currentMember === teamConfirmations[id].captain;
        }
      }

      return false;
    },
    async showCompositionForm(id, addPlayerId) {
      try {
        // Add player to the composition?
        this.addPlayerToComposition = addPlayerId;

        const teamConfirmations =
          this.$store.getters['statistics/teamConfirmations'];
        const teamCompositionId = teamConfirmations[id].teamCalendarId;

        // Get/update current composition
        await this.$store.dispatch('teams/loadTeamCompositions', {
          id: teamCompositionId,
        });

        // Get available players
        await this.$store.dispatch('teams/loadAvailableTeamPlayers', {
          id: teamCompositionId,
        });
        const teamCompositions = this.$store.getters['teams/teamCompositions'];
        this.currentTeamComposition = teamCompositions.find(
          (teamComposition) => teamComposition.id === teamCompositionId
        );

        if (this.currentTeamComposition) {
          this.availableTeamPlayers =
            this.$store.getters['teams/availableTeamPlayers'];
          this.compositionFormIsVisible = true;
        }
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de beschikbare spelers!';
      }
    },
    closeCompositionForm() {
      this.compositionFormIsVisible = false;
    },
    async saveComposition() {
      this.compositionFormIsVisible = false;
      this.getConfirmations();
    },
  },
  created() {
    this.compositionsNeedRelease =
      this.$store.getters['compositionsNeedRelease'];

    this.loadStatistics();
  },
};
</script>

<style scoped>
.buttons {
  margin-top: 10px;
}

.boxed {
  overflow: scroll;
  overflow-y: hidden;
  padding-bottom: 10px;
}

.teamselection {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.club {
  color: var(--var-club);
  font-weight: bold;
}

.captain {
  font-weight: bold;
  color: var(--var-captain);
}

.playerissetup {
  font-weight: bold;
}

.playerisnoteamplayer {
  font-style: italic;
  color: var(--var-noteamplayer);
}

.ical {
  width: 100%;
  font-size: 14px;
  cursor: pointer;
}

.won {
  color: var(--var-won);
  font-weight: bold;
}
.lost {
  color: var(--var-lost);
  font-weight: bold;
}
.draw {
  color: var(--var-draw);
  font-weight: bold;
}

.meetingstatus {
  font-size: small;
  font-style: italic;
  color: var(--var-meetingstatus);
}

.primary {
  color: var(--var-sl-primary);
}

.success {
  color: var(--var-sl-success);
}

.neutral {
  color: var(--var-sl-neutral);
}

.warning {
  color: var(--var-sl-warning);
}

.danger {
  color: var(--var-sl-danger);
}

.asktoplay {
  color: var(--var-asktoplay);
  font-weight: lighter;
  cursor: pointer;
}

.asktosetup {
  color: var(--var-asktosetup);
}

.issetup {
  color: var(--var-won);
}

.playerremarks {
  font-size: small;
  font-style: italic;
  color: var(--var-playerremark);
}

.columntitle {
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
}

.checkboxtext {
  /* Checkbox text */
  font-size: 12px;
  display: inline;
}

.confirmationselections {
  height: 10px;
  padding-left: 2px;
}

.compositioncolumn {
  cursor: pointer;
}

.canconfirmcolumn {
  cursor: pointer;
}
</style>
