<template>
  <v-btn @click="setTeam" :color="selectedColor">
    {{ team }}
  </v-btn>
</template>

<script>
export default {
  props: ['team', 'selectedteam'],
  emits: ['set-team'],
  computed: {
    selectedColor() {
      if (this.selectedteam && this.selectedteam == this.team) {
        return 'primary';
      } else {
        return '';
      }
    },
  },
  methods: {
    setTeam() {
      this.$emit('set-team', {
        team: this.team,
      });
    },
  },
};
</script>

<style scoped></style>
