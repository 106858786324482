export default {
  apiUrl(state) {
    return state.apiUrl;
  },
  token(state) {
    return state.token;
  },
  appName(state) {
    return state.appName;
  },
  appDescription(state) {
    return state.appDescription;
  },
  homePage(state) {
    return state.homePage;
  },
  messageOfTheDay(state) {
    return state.messageOfTheDay;
  },
  passwordUrl(state) {
    return state.passwordUrl;
  },
  loggedIn(state) {
    return !!state.loginId;
  },
  clubName(state) {
    return state.clubName;
  },
  clubCode(state) {
    return state.clubCode;
  },
  association(state) {
    return state.association;
  },
  isTableTennis(state) {
    return state.association == 'VTTL' || state.association == 'SPORTA'
      ? true
      : false;
  },
  isBadminton(state) {
    return state.association == 'BV' ? true : false;
  },
  isGym(state) {
    return state.association == 'GYMFED' ? true : false;
  },
  isPadel(state) {
    return state.association == 'PADEL' ? true : false;
  },
  disciplines(state) {
    return state.disciplines;
  },
  hasSportsCamp(state) {
    return state.hasSportsCamp;
  },
  loginId(state) {
    return state.loginId;
  },
  memberId(state) {
    return state.memberId;
  },
  memberIds(state) {
    return state.memberIds;
  },
  trainingModuleActive(state) {
    return state.trainingModuleActive;
  },
  sportsCampModuleActive(state) {
    return state.sportsCampModuleActive;
  },
  trainerId(state) {
    return state.trainerId;
  },
  additionalTraineeSelection(state) {
    return state.additionalTraineeSelection;
  },
  firstName(state) {
    return state.firstName;
  },
  lastName(state) {
    return state.lastName;
  },
  email(state) {
    return state.email;
  },
  frenoyUrl(state) {
    return state.frenoyUrl;
  },
  frenoyPublicUrl(state) {
    return state.frenoyPublicUrl;
  },
  compositionsNeedRelease(state) {
    return state.compositionsNeedRelease;
  },
  myTeams(state) {
    return state.myTeams;
  },
  playerMoments(state) {
    return state.playerMoments;
  },
  showPlayerMomentsInTheFuture(state) {
    return state.showPlayerMomentsInTheFuture;
  },
  canComposeAsCaptain(state) {
    return state.composeAsCaptain;
  },
  canComposeAnyTeam(state) {
    return state.composeAnyTeam;
  },
  hasAvailableContributionForms(state) {
    return (
      state.availableContributionForms &&
      state.availableContributionForms.length > 0
    );
  },
  availableContributionForms(state) {
    return state.availableContributionForms;
  },
  contributionFormURL(state) {
    return state.contributionFormURL;
  },
  didAutoLogout(state) {
    return state.didAutoLogout;
  },
  isNavOpen(state) {
    return state.isNavOpen;
  },
  myMembers(state) {
    return state.myMembers;
  },
  trainingGroups(state) {
    let tg = '';
    const myMembers = state.myMembers;
    if (myMembers.length > 0) {
      myMembers.forEach(member => {
        if (member.selected) {
          tg = member.trainingGroups;
        }
      });
    }
    return tg;
  },
  gdprExplicitAllowance(state) {
    return state.gdprExplicitAllowance;
  },
  gdprMailings(state) {
    return state.gdprMailings;
  },
  gdprSmses(state) {
    return state.gdprSmses;
  },
  gdprBirthday(state) {
    return state.gdprBirthday;
  },
  gdprPhoto(state) {
    return state.gdprPhoto;
  },
  personalInformation(state) {
    return state.personalInformation;
  },
  frenoyResults(state) {
    return state.frenoyResults;
  },
  teamCompositionModuleActive(state) {
    return state.teamCompositionModuleActive;
  },
  teamConfirmationOptions(state) {
    return state.teamConfirmationOptions;
  },
  hasLeague(state) {
    return state.hasLeague;
  },
  leaguePlayerMomentInfo(state) {
    return state.leaguePlayerMomentInfo;
  },
  canEnterAnyLeagueResult(state) {
    return state.enterAnyLeagueResult;
  },
  isSubscribedForTheLeague(state) {
    return state.isSubscribedForTheLeague;
  },
  canUnsubscribeFromLeague(state) {
    return state.canUnsubscribeFromLeague;
  },
  isLicensed() {
    const licenseEndDate = new Date('2050-09-01');
    const thisDate = new Date();

    return thisDate <= licenseEndDate;
  },
  transportTypes(state) {
    return state.transportTypes;
  }
};
