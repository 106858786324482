<template>
  <base-popup :show="isLoading" :fixed="true" title="Even geduld aub...">
    <div>
      <base-spinner></base-spinner>
    </div>
  </base-popup>
  <base-popup :show="!!error" title="Er trad een fout op!" @close="handleError">
    <p>{{ error }}</p>
  </base-popup>
  <base-popup
    :show="iCalFormSentIsVisible"
    title="Het e-mailbericht werd verstuurd"
    awesomeicon="far fa-envelope"
    @close="hideICalFormSent"
  >
    <template v-slot:default>
      <div>
        Het e-mailbericht werd verstuurd aan <strong>{{ email }}</strong
        >.
      </div>
      <div class="mailinfo">
        <em
          >Eventjes geduld wel, het kan even duren alvorens het bericht in je
          mailbox toekomt!<br />
          Vergeet ook niet even bij je spamberichten te zien!</em
        >
      </div>
    </template>
  </base-popup>
  <base-popup
    :show="copiedToClipboard"
    title="URL koppeling"
    @close="closeCopiedToClipboard"
  >
    <p>
      De URL koppeling werd naar het klembord gekopieerd, je kan de gekopieerde
      URL gebruiken om deze kalender toe te voegen aan je Google of Outlook
      kalender.
    </p>
    <p>
      Klik <a href="https://youtu.be/zKjrcr68V7U" target="_blank">hier</a> voor
      een beknopte uitleg hoe je met deze gekopieerde URL de competitiekalender
      voor deze ploeg aan jouw Google of Outlook kalender kan toevoegen.
    </p>
  </base-popup>
  <div>
    <div v-if="!!teamUrl">
      Kopieer de iCal URL naar het klembord, of verstuur een e-mailbericht met
      de kalendergegevens aan <strong>{{ email }}</strong>
    </div>
    <div v-else>
      Verstuur een e-mailbericht met de kalendergegevens aan
      <strong>{{ email }}</strong>
    </div>
  </div>
  <hr />
  <div class="form-control">
    <div v-if="!!teamUrl">
      <base-button mode="smallbold" @click="copyToClipboard"
        ><i class="far fa-copy"></i>&nbsp;Kopieer de iCal<br />
        URL naar het klembord</base-button
      >
    </div>
    <div>
      <base-button mode="smallbold" @click="sendIcalFileForMeeting"
        ><i class="far fa-envelope"></i>&nbsp;Enkel voor de ontmoeting
        <div v-html="datedescription"></div
      ></base-button>
    </div>
    <div>
      <base-button mode="smallbold" @click="sendIcalFileForTeam"
        ><i class="far fa-envelope"></i>&nbsp;Voor alle ontmoetingen<br />
        van de ploeg<br />{{ teamdescription }}</base-button
      >
    </div>
  </div>
  <hr v-if="!!teamUrl" />
  <div v-if="!!teamUrl">
    <em
      ><small
        >iCal URL: {{ teamUrl }}<br />
        Klik
        <a href="https://youtu.be/zKjrcr68V7U" target="_blank">hier</a> voor een
        beknopte uitleg hoe je met deze gekopieerde URL de competitiekalender
        voor deze ploeg aan jouw Google of Outlook kalender kan
        toevoegen.</small
      ></em
    >
  </div>
</template>

<script>
export default {
  props: [
    'id',
    'teamid',
    'teamdescription',
    'weekdescription',
    'datedescription',
    'icalurl'
  ],
  emits: ['close-form'],
  data() {
    return {
      email: null,
      isLoading: false,
      error: null,
      iCalFormSentIsVisible: false,
      copiedToClipboard: false,
      teamUrl: ''
    };
  },
  methods: {
    handleError() {
      this.error = null;
    },
    async sendIcalFileForMeeting() {
      if (
        confirm(
          'Wens je een e-mailbericht met de afspraakgegevens voor deze ontmoeting?\n\n' +
            '(Je kan deze datum dan eenvoudig aan je agenda toevoegen)'
        )
      ) {
        try {
          this.isLoading = true;

          // Send iCalfiles
          await this.$store.dispatch('teams/sendIcalEventFile', {
            teamcalendarId: this.id
          });

          this.iCalFormSentIsVisible = true;
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het versturen van de gegevens!';
        }

        this.isLoading = false;
      }
    },
    async sendIcalFileForTeam() {
      if (
        confirm(
          `Wens je e-mailbericht(en) met de (komende) afspraakdata voor de ploeg ${this.teamdescription}?\n\n(Je kan deze afspraakdata dan eenvoudig aan je agenda toevoegen)`
        )
      ) {
        try {
          this.isLoading = true;

          // Send iCalfiles
          await this.$store.dispatch('teams/sendIcalEventFile', {
            teamId: this.teamid
          });

          this.iCalFormSentIsVisible = true;
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het versturen van de gegevens!';
        }

        this.isLoading = false;
      }
    },
    hideICalFormSent() {
      this.iCalFormSentIsVisible = false;
      this.$emit('close-form');
    },
    copyToClipboard() {
      navigator.clipboard
        .writeText(this.teamUrl)
        .then((this.copiedToClipboard = true));
    },
    closeCopiedToClipboard() {
      this.copiedToClipboard = false;
      this.$emit('close-form');
    },
    closeForm() {
      this.$emit('close-form');
    }
  },
  mounted() {
    this.email = this.$store.getters['email'];
    this.teamUrl = this.icalurl; // 'https://www.ttclobos.be/mytabnet/api/calendar.php?guid=ABCDEF';
  }
};
</script>

<style scoped>
.form-control {
  margin: 0.5rem 0;
  display: flex;
}
</style>
