<template>
  <the-header></the-header>
  <router-view :key="$route.fullPath" v-slot="slotProps">
    <transition name="route" mode="out-in">
      <component :is="slotProps.Component"></component>
    </transition>
  </router-view>
</template>

<script>
import TheHeader from './components/layout/TheHeader.vue';
// import settings from 'raw-loader!./../settings.txt';

export default {
  components: {
    TheHeader
  },
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    }
  },
  created() {
    if (process.env.NODE_ENV === 'production') {
      fetch('./settings.txt', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        response.json().then(json => {
          this.$store.dispatch('appConfig', {
            title: json.config.title,
            description: json.config.description,
            apiUrl: json.config.apiUrl,
            passwordUrl: json.config.passwordUrl
          });
          this.$store.dispatch('tryLogin');
          const loggedIn = this.$store.getters['loggedIn'];
          if (loggedIn) {
            this.$store.dispatch('loadMyMembers');
            this.$store.dispatch('loadTeamConfirmationOptions');
          }
        });
      });
    } else {
      this.$store.dispatch('appConfig', {
        // title: 'MyTabNet - KTTK VILVO',
        // title: 'MyTabNet - LOCAL DEV',
        apiUrl: 'https://www.ttclobos.be/mytabnet/api/',
        // apiUrl: 'https://www.kttkvilvo.be/mytabnetmobile/api/',
        // title: 'MyTabNet - TTC ledenfiches.be',
        // title: 'MyGymNet TEST - TC Flik-Flak Zaffelare',
        // apiUrl: 'https://www.flikflakzaffelare.be/mygymnetmobile/api/',
        // description:
        //  'Met deze app kan je de ploegopstellingen raadplegen, ledenfiches opzoeken, de sterktelijst(en) inkijken alsook, door de speelmoment-verantwoordelijken, de aanwezigheden/speelmoment aanduiden. Tenslotte kunnen trainers de trainingswerking & sportkampinformatie consulteren.',
        // apiUrl: 'https://www.ledenfiches.be/applications/api/',
        // apiUrl: 'https://www.tcdekrijte.be/ledenbeheer/api/',
        // description: 'MYPADELNET TEST',
        // description: 'Tafeltennisvereniging',
        //  'OPGELET: development environment! Connectie met de gegevensbank van Flik-Flak!',
        // apiUrl: 'https://ledenbeheer-test.pluimplukkers.be/api/',
        // apiUrl: 'https://www.ttcsinttruiden.be/mytabnet/api/',
        // apiUrl: 'https://www.ttcw.be/mytabnetmobile/api/',
        // apiUrl: 'https://www.ttcgalmaarden.be/mytabnetmobile/vttl/api/',
        passwordUrl: '',
        description:
          '<p>Met deze app kan je de poules, klassementen en resultaten opvragen en, als kapitein, de resultaten inbrengen.</p><p>Selecteer de league waarvoor je de resultaten wenst op te vragen.</p>'
      });
    }

    //    const settingsObj = JSON.parse(settings);
  },
  watch: {
    didAutoLogout(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace('/login');
      }
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://use.fontawesome.com/releases/v5.13.0/css/all.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/css/bootstrap.min.css');
@import url('./../settings.css');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
}

body {
  margin: 0;
  color: var(--var-font-color);
}

a {
  color: var(--var-font-hyperlink);
}

a:hover {
  color: var(--var-font-hyperlink-hover);
}

.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}

#navigation-icon > i {
  cursor: pointer;
  font-size: 2rem;
  padding: 4px;
}
</style>
