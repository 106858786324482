<template>
  <div>
    <base-popup
      :show="!!error"
      title="Fout/waarschuwing"
      awesomeicon="fas fa-exclamation-triangle"
      @close="handleErrorAndClose"
    >
      <p class="error">{{ error }}</p>
    </base-popup>
    <section>
      <base-card>
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div v-else-if="thereAreResults">
          <h3>Mijn resultaten</h3>
          <hr />
          <v-card>
            <v-tabs v-model="tab" bg-color="#d0ebff">
              <v-tab value="rankings"
                ><v-icon icon="mdi-chart-bar"></v-icon>
                <div v-if="!isMobile">Klassementen</div></v-tab
              >
              <v-tab value="games"
                ><v-icon icon="mdi-trophy"></v-icon>
                <div v-if="!isMobile">Wedstrijden</div></v-tab
              >
              <v-tab value="results"
                ><v-icon icon="mdi-percent"></v-icon>
                <div v-if="!isMobile">Weekresultaten</div></v-tab
              >
              <v-tab value="elo"
                ><v-icon icon="mdi-bullseye"></v-icon>
                <div v-if="!isMobile">ELO/BEL</div></v-tab
              >
            </v-tabs>

            <v-card-text>
              <v-window v-model="tab">
                <v-window-item value="rankings">
                  <div class="boxed">
                    <table
                      cellspacing="0"
                      cellpadding="0"
                      border="0"
                      width="390"
                    >
                      <tr>
                        <td width="50px"></td>
                        <td width="50px" style="text-align: right"><b>+</b></td>
                        <td width="50px" style="text-align: right"><b>-</b></td>
                        <td width="90px" style="text-align: right"><b>%</b></td>
                        <td width="150px" style="text-align: right"></td>
                      </tr>
                      <tr
                        v-for="RankingResult in frenoyResults.RankingResults"
                        :key="RankingResult.Ranking"
                      >
                        <td width="50px">
                          <b>{{ RankingResult.Ranking }}</b>
                        </td>
                        <td width="50px" style="text-align: right">
                          {{ RankingResult.Won }}
                        </td>
                        <td width="50px" style="text-align: right">
                          {{ RankingResult.Lost }}
                        </td>
                        <td width="90px" style="text-align: right">
                          {{ RankingResult.PercentageString }}
                        </td>
                        <td width="150px" style="padding-left: 25px">
                          <div
                            class="progress"
                            :class="progressClass(RankingResult.Percentage)"
                          >
                            <div
                              class="bar"
                              v-if="RankingResult.Percentage >= 10"
                            ></div>
                            <div
                              class="bar"
                              v-if="RankingResult.Percentage >= 20"
                            ></div>
                            <div
                              class="bar"
                              v-if="RankingResult.Percentage >= 30"
                            ></div>
                            <div
                              class="bar"
                              v-if="RankingResult.Percentage >= 40"
                            ></div>
                            <div
                              class="bar"
                              v-if="RankingResult.Percentage >= 50"
                            ></div>
                            <div
                              class="bar"
                              v-if="RankingResult.Percentage >= 60"
                            ></div>
                            <div
                              class="bar"
                              v-if="RankingResult.Percentage >= 70"
                            ></div>
                            <div
                              class="bar"
                              v-if="RankingResult.Percentage >= 80"
                            ></div>
                            <div
                              class="bar"
                              v-if="RankingResult.Percentage >= 90"
                            ></div>
                            <div
                              class="bar"
                              v-if="RankingResult.Percentage >= 100"
                            ></div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </v-window-item>

                <v-window-item value="games">
                  <div class="boxed">
                    <table
                      cellspacing="0"
                      cellpadding="0"
                      border="0"
                      width="535"
                    >
                      <tr>
                        <td width="25px"></td>
                        <td width="200px" style="text-align: left">
                          <b>Naam</b>
                        </td>
                        <td width="30px" style="text-align: left"><b>Kl</b></td>
                        <td width="40px" style="text-align: left">
                          <b>Uitslag</b>
                        </td>
                        <td width="5px"></td>
                        <td width="170px" style="text-align: left">
                          <b>Club</b>
                        </td>
                      </tr>
                      <tr
                        v-for="DetailResult in frenoyResults.DetailResults"
                        :key="DetailResult.MatchIndex"
                      >
                        <td width="25px" style="text-align: center">
                          <b>{{ DetailResult.MatchIndex }}</b>
                        </td>
                        <td width="200px" style="text-align: left">
                          {{ DetailResult.LastName }}
                          {{ DetailResult.FirstName }}
                        </td>
                        <td width="30px" style="text-align: left">
                          {{ DetailResult.Ranking }}
                        </td>
                        <td
                          width="40px"
                          :class="resultClass(DetailResult.Result)"
                          style="text-align: center"
                        >
                          {{ DetailResult.SetFor }} -
                          {{ DetailResult.SetAgainst }}
                        </td>
                        <td width="5px"></td>
                        <td width="170px" style="text-align: left">
                          {{ DetailResult.Club }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </v-window-item>

                <v-window-item value="results">
                  <div class="boxed">
                    <table
                      cellspacing="0"
                      cellpadding="0"
                      border="0"
                      width="650"
                    >
                      <tr>
                        <td width="100px"></td>
                        <td width="40px" style="text-align: center">
                          <b>%</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>1</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>2</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>3</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>4</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>5</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>6</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>7</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>8</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>9</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>10</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>11</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>12</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>13</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>14</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>15</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>16</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>17</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>18</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>19</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>20</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>21</b>
                        </td>
                        <td width="15px" style="text-align: center">
                          <b>22</b>
                        </td>
                        <td width="30px" style="text-align: center">
                          <b>#</b>
                        </td>
                        <td width="35px" style="text-align: center"></td>
                      </tr>
                      <tr
                        v-for="WeekResult in frenoyResults.WeekResults"
                        :key="WeekResult.Team"
                      >
                        <td width="100px" style="text-align: left">
                          <b>{{ WeekResult.Team }}</b>
                        </td>
                        <td width="40px" style="text-align: center">
                          {{ WeekResult.PercentageString }}
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week01"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week02"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week03"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week04"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week05"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week06"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week07"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week08"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week09"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week10"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week11"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week12"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week13"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week14"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week15"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week16"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week17"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week18"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week19"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week20"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week21"></div>
                        </td>
                        <td width="15px" style="text-align: center">
                          <div v-html="WeekResult.Week22"></div>
                        </td>
                        <td width="30px" style="text-align: center">
                          {{ WeekResult.Entries }}
                        </td>
                        <td width="35px" style="text-align: center">
                          {{ WeekResult.Saldo }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </v-window-item>

                <v-window-item value="elo">
                  <div class="inputs">
                    <div class="inputlabel">ELO-punten</div>
                    <div class="pointsfield">
                      <input
                        id="ELOPoints"
                        v-model="frenoyResults.ELOPoints"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="inputs">
                    <div class="inputlabel">BEL-punten</div>
                    <div class="pointsfield">
                      <input
                        id="BELPoints"
                        v-model="frenoyResults.BELPoints"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="inputs">
                    <div class="inputlabel">BELPOS-punten</div>
                    <div class="pointsfield">
                      <input
                        id="BELPoints"
                        v-model="frenoyResults.BELPosPoints"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="inputs">
                    <div class="inputlabel">Voorspeld klassement</div>
                    <div class="pointsfield">
                      <input
                        id="Residu"
                        v-model="frenoyResults.Prognose"
                        disabled
                      />
                    </div>
                  </div>
                </v-window-item>
              </v-window>
            </v-card-text>
          </v-card>
        </div>
        <div v-else>
          <p>
            <i class="fas fa-info-circle"></i>&nbsp;Er zijn nog geen resultaten
            beschikbaar voor het actuele seizoen!
          </p>
        </div>
        <hr />
        <div class="buttons">
          <v-btn @click="openFrenoy"
            ><v-icon icon="mdi-chart-bell-curve-cumulative"></v-icon>&nbsp;Open
            mijn resultaten op Frenoy</v-btn
          >
        </div>
      </base-card>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      isLoading: false,
      tab: null,
      frenoyResults: {},
      error: null,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 540;
    },
    tabList() {
      let tabs = [
        '<i class="fas fa-chart-line"></i><div>Klassementen</div>',
        '<i class="fas fa-calculator"></i><div>ELO/BEL</div>',
        '<i class="fas fa-signal"></i><div>Wedstrijden</div>',
        '<i class="fas fa-balance-scale-right"></i><div>Weekresultaten</div>',
      ];
      // console.log(window.innerWidth);
      if (window.innerWidth < 540) {
        tabs = [
          '<i class="fas fa-chart-line"></i>',
          '<i class="fas fa-calculator"></i>',
          '<i class="fas fa-signal"></i>',
          '<i class="fas fa-balance-scale-right"></i>',
        ];
      }

      return tabs;
    },
    thereAreResults() {
      return this.frenoyResults.ResultsAvailable;
    },
    progressClass() {
      return (percentage) => {
        if (percentage >= 100) {
          return 'p100';
        }
        if (percentage >= 90) {
          return 'p90';
        }
        if (percentage >= 80) {
          return 'p80';
        }
        if (percentage >= 70) {
          return 'p70';
        }
        if (percentage >= 60) {
          return 'p60';
        }
        if (percentage >= 50) {
          return 'p50';
        }
        if (percentage >= 40) {
          return 'p40';
        }
        if (percentage >= 30) {
          return 'p30';
        }
        if (percentage >= 20) {
          return 'p20';
        }
        if (percentage >= 10) {
          return 'p10';
        }

        return 'p0';
      };
    },
    resultClass() {
      return (MatchResult) => {
        if (MatchResult == 'D') {
          return 'lost';
        } else {
          return 'won';
        }
      };
    },
  },
  methods: {
    async loadFrenoyResults() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('loadFrenoyResults', {}).then(() => {
          this.frenoyResults = this.$store.getters['frenoyResults'];

          // console.log(this.frenoyResults);
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het opvragen van je individuele resultaten op Frenoy!';
      }

      this.isLoading = false;

      // Close navigation
      if (this.$store.getters.isNavOpen) {
        this.$store.dispatch('toggleNav');
      }
    },
    openFrenoy() {
      const url = this.$store.getters.frenoyPublicUrl;
      window.open(url);
    },
    handleError() {
      this.error = null;
    },
    handleErrorAndClose() {
      this.error = null;
    },
  },
  created() {
    this.loadFrenoyResults();
  },
  mounted() {
    // Zorgt ervoor dat via switch member kan gerefreshed worden
    if (this.$route.params.refresh) {
      this.loadFrenoyResults();
    }
  },
};
</script>

<style scoped>
.buttons {
  margin-top: 10px;
}

.inputs {
  width: 100%;
  display: flex;
  margin-bottom: 5px;
}

.inputlabel {
  width: 140px;
  font-size: 14px;
  font-weight: bold;
  padding-top: 5px;
}

.pointsfield {
  width: 40px;
  padding-left: 10px;
}
.boxed {
  overflow: scroll;
  overflow-y: hidden;
  padding-bottom: 10px;
}

.progress {
  width: 82px;
}

.p100 {
  background-color: var(--var-won);
}

.p90 {
  background-color: #69db7c;
}
.p80 {
  background-color: #8ce99a;
}

.p70 {
  background-color: #b2f2bb;
}
.p60 {
  background-color: #c0eb75;
}
.p50 {
  background-color: #ffec99;
}
.p40 {
  background-color: #fab005;
}

.p30 {
  background-color: #ff922b;
}

.p20 {
  background-color: #fd7e14;
}
.p10 {
  background-color: #e8590c;
}
.p0 {
  background-color: var(--var-lost);
}
div.bar {
  height: 15px;
  width: 6px;
  background-color: #d0ebff;
  position: relative;
  border-radius: 2px;
  box-shadow: 0px 0px 5px #74c0fc;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 100px;
  top: 3px;
  float: left;
  clear: top;
}

.won {
  background-color: var(--var-won);
}

.lost {
  background-color: var(--var-lost);
}
</style>
