<template>
  <base-popup
    :show="!!error"
    title="Fout/waarschuwing"
    awesomeicon="fas fa-exclamation-triangle"
    @close="handleErrorAndClose"
  >
    <p class="error">{{ error }}</p>
    <!-- <base-button @click="handleError" mode="smallbold">Terug</base-button> -->
    <!-- <base-button @click="handleErrorAndClose" mode="smallbold">Sluit</base-button> -->
  </base-popup>
  <form @submit.prevent="closeForm">
    <base-tabs tabId="tc" :tabList="tabList" fontsize="small" bold="false">
      <template v-slot:tabPanel-tc1>
        <div class="boxed">
          <div class="players">
            <div :class="playerLabelClass" v-html="playerLabel(1)"></div>
            <div class="playerselection">
              <multiselect
                v-model="player1"
                :options="playerSelection"
                :searchable="true"
                placeholder="Selecteer speler"
                ref="player1"
              ></multiselect>
            </div>
          </div>
          <div class="players" v-if="nrofplayersperteam >= 2">
            <div :class="playerLabelClass" v-html="playerLabel(2)"></div>
            <div class="playerselection">
              <multiselect
                v-model="player2"
                :options="playerSelection"
                :searchable="true"
                placeholder="Selecteer speler"
                ref="player2"
              ></multiselect>
            </div>
          </div>
          <div class="players" v-if="nrofplayersperteam >= 3">
            <div :class="playerLabelClass" v-html="playerLabel(3)"></div>
            <div class="playerselection">
              <multiselect
                v-model="player3"
                :options="playerSelection"
                :searchable="true"
                placeholder="Selecteer speler"
                ref="player3"
              ></multiselect>
            </div>
          </div>
          <div class="players" v-if="nrofplayersperteam >= 4">
            <div :class="playerLabelClass" v-html="playerLabel(4)"></div>
            <div class="playerselection">
              <multiselect
                v-model="player4"
                :options="playerSelection"
                :searchable="true"
                placeholder="Selecteer speler"
                ref="player4"
              ></multiselect>
            </div>
          </div>
          <div class="players" v-if="nrofplayersperteam >= 5">
            <div :class="playerLabelClass" v-html="playerLabel(5)"></div>
            <div class="playerselection">
              <multiselect
                v-model="player5"
                :options="playerSelection"
                :searchable="true"
                placeholder="Selecteer speler"
                ref="player5"
              ></multiselect>
            </div>
          </div>
          <div class="players" v-if="nrofplayersperteam >= 6">
            <div :class="playerLabelClass" v-html="playerLabel(6)"></div>
            <div class="playerselection">
              <multiselect
                v-model="player6"
                :options="playerSelection"
                :searchable="true"
                placeholder="Selecteer speler"
                ref="player6"
              ></multiselect>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:tabPanel-tc2>
        <div class="boxed">
          <div class="players">
            <div :class="playerLabelClass" v-html="reserveLabel(1)"></div>
            <div class="playerselection">
              <multiselect
                v-model="reserve1"
                :options="playerSelection"
                :searchable="true"
                placeholder="Selecteer reserve"
                ref="reserve1"
              ></multiselect>
            </div>
          </div>
          <div class="players">
            <div :class="playerLabelClass" v-html="reserveLabel(2)"></div>
            <div class="playerselection">
              <multiselect
                v-model="reserve2"
                :options="playerSelection"
                :searchable="true"
                placeholder="Selecteer reserve"
                ref="reserve2"
              ></multiselect>
            </div>
          </div>
          <div class="players">
            <div :class="playerLabelClass" v-html="reserveLabel(3)"></div>
            <div class="playerselection">
              <multiselect
                v-model="reserve3"
                :options="playerSelection"
                :searchable="true"
                placeholder="Selecteer reserve"
                ref="reserve3"
              ></multiselect>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:tabPanel-tc3>
        <div class="boxed">
          <p class="remarktext">
            Breng hier de weer te geven opmerking in, bijvoorbeeld m.b.t. de
            bereikbaarheid van de zaal of wegenwerken.
          </p>
          <textarea id="remarks" v-model.trim="inputRemarks" rows="5" />
        </div>
      </template>
      <template v-slot:tabPanel-tc4>
        <div v-html="confirmed" class="boxed"></div>
      </template>
      <template v-slot:tabPanel-tc5>
        <div v-html="denied" class="boxed"></div>
      </template>
      <template v-slot:tabPanel-tc6>
        <div class="boxed">
          <ul>
            <li
              v-for="availableTeamPlayer in availableteamplayers"
              :key="availableTeamPlayer.id"
            >
              {{ availableTeamPlayer.listNumber
              }}<span v-if="isTableTennis"
                >/{{ availableTeamPlayer.indexNumber }}</span
              >
              .
              {{ availableTeamPlayer.playerName }}
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:tabPanel-tc7>
        <div class="boxed logs">
          <table style="width:100%" v-if="!logError">
            <tr>
              <th style="width:25%">Speler</th>
              <th style="width:45%">Aanpassing</th>
              <th style="width:30%">Aangepast op/door</th>
            </tr>
            <tr v-for="log in compositionLogs" :key="log.id">
              <td>{{ log.member }}</td>
              <td>{{ log.modification }}</td>
              <td>{{ log.logDate }} {{ log.userName }}</td>
            </tr>
          </table>

          <div v-else>
            {{ logError }}
          </div>
        </div>
      </template>
    </base-tabs>
    <hr />
    <div>
      <span class="form-check form-switch">
        <label for="email" class="emailtext" v-html="emailText"></label>
        <input
          class="form-check-input"
          type="checkbox"
          id="email"
          :checked="sendEmail"
          @change="setSendEmail"
        />
      </span>
    </div>
    <div class="buttons">
      <base-button @click="submitForm" mode="smallbold">Opslaan</base-button>
      <base-button @click="closeForm" mode="smallbold">Sluit</base-button>
    </div>
  </form>
</template>

<script>
import Multiselect from '@vueform/multiselect';

export default {
  components: {
    Multiselect
  },
  props: [
    'id',
    'team',
    'nrofplayersperteam',
    'startplayerindex',
    'availableteamplayers',
    'teamplayers',
    'teamreserves',
    'confirmed',
    'denied',
    'remarks',
    'addplayer'
  ],
  emits: ['save-composition', 'close-composition'],
  data() {
    return {
      player1: null,
      player2: null,
      player3: null,
      player4: null,
      player5: null,
      player6: null,
      reserve1: null,
      reserve2: null,
      reserve3: null,
      inputRemarks: null,
      error: null,
      sendEmail: false,
      logError: null
    };
  },
  computed: {
    isTableTennis() {
      return this.$store.getters['isTableTennis'];
    },
    tabList() {
      if (window.innerWidth < 540) {
        return ['Sp', 'Res', 'Opm', 'Bev', 'Afm', 'SL', 'Log'];
      } else {
        return [
          'Spelers',
          'Reserves',
          'Opmerking',
          'Bevestigingen',
          'Afmeldingen',
          'Sterktelijst',
          'Logs'
        ];
      }
    },
    playerLabelClass() {
      if (window.innerWidth < 540) {
        return 'playerlabelmobile';
      }
      return 'playerlabel';
    },
    playerSelection() {
      const isPadel = this.$store.getters['isPadel'];

      // console.log(this.startplayerindex);
      let minIndex = 0;
      if (!isPadel) {
        minIndex = this.startplayerindex;
        // console.log(minIndex);
      }
      // console.log(this.availableteamplayers);
      const players = [];
      for (let i = 0; i < this.availableteamplayers.length; i++) {
        if (minIndex <= parseInt(this.availableteamplayers[i].indexNumber)) {
          const player = {
            value: this.availableteamplayers[i].id,
            label:
              this.availableteamplayers[i].listNumber +
              (isPadel ? '' : '/' + this.availableteamplayers[i].indexNumber) +
              '. ' +
              this.availableteamplayers[i].playerName
          };

          players.push(player);
        }
      }
      // console.log(players);

      return players;
    },
    emailText() {
      if (window.innerWidth < 540) {
        return 'Spelers informeren';
      }
      return 'Stuur deze opstelling aan alle spelers';
    },
    compositionLogs() {
      const logs = this.$store.getters['teams/compositionLogs'];

      return logs;
    }
  },
  methods: {
    handleError() {
      this.error = null;
    },
    handleErrorAndClose() {
      this.error = null;
      this.$emit('save-composition');
    },
    playerLabel(index) {
      if (window.innerWidth < 540) {
        return '<strong>' + index + '.</strong>';
      }
      return 'Speler ' + index;
    },
    reserveLabel(index) {
      // console.log(window.innerWidth);
      if (window.innerWidth < 540) {
        return '<strong>' + index + '.</strong>';
      } else {
        return 'Res.' + index;
      }
    },
    closeForm() {
      this.$emit('close-composition');
    },
    setSendEmail() {
      this.sendEmail = !this.sendEmail;
    },
    async submitForm(e) {
      e.preventDefault();
      try {
        await this.$store
          .dispatch('teams/setTeamComposition', {
            id: this.id,
            player1: this.player1,
            player2: this.player2,
            player3: this.player3,
            player4: this.player4,
            player5: this.player5,
            player6: this.player6,
            reserve1: this.reserve1,
            reserve2: this.reserve2,
            reserve3: this.reserve3,
            remarks: this.inputRemarks
          })
          .then(() => {
            // Show informative errordialog
            const compositionList = this.$store.getters[
              'teams/teamCompositions'
            ];
            const composition = compositionList.find(
              composition => composition.id === this.id
            );
            // console.log(composition);
            if (
              composition.error &&
              composition.error !== 'Correcte teamopstelling'
            ) {
              this.error = composition.error;
            } else {
              this.$emit('save-composition');
            }
          });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het doorvoeren van deze ploegopstelling!';
      }
      if (this.sendEmail) {
        try {
          await this.$store.dispatch('teams/sendTeamComposition', {
            id: this.id
          });
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het uitsturen van de e-mailberichten aan de ploegspelers!';
        }
      }
    },
    async loadCompositionLogs() {
      try {
        this.logError = null;

        // Get/update current composition
        await this.$store.dispatch('teams/loadCompositionLogs', {
          id: this.id
        });
      } catch (err) {
        this.logError =
          err.message ||
          'Er trad een probleem op bij het ophalen van de opstellingslogs!';
      }
    }
  },
  mounted() {
    let playerIsPresent = false;
    let playersAdded = 0;

    const p = this.teamplayers.split(',');
    for (let i = 0; i < p.length; i++) {
      if (i == 0) {
        this.player1 = p[i];
      }
      if (i == 1) {
        this.player2 = p[i];
      }
      if (i == 2) {
        this.player3 = p[i];
      }
      if (i == 3) {
        this.player4 = p[i];
      }
      if (i == 4) {
        this.player5 = p[i];
      }
      if (i == 5) {
        this.player6 = p[i];
      }

      if (p[i] == this.addplayer) {
        playerIsPresent = true;
      }

      if (p[i]) {
        playersAdded++;
      }
    }

    // console.log('PlayerIsPresent: ', playerIsPresent);
    // console.log('Playersadded: ', playersAdded);
    // console.log('Player to add: ', this.addplayer);
    // console.log('Nrofplayersperteam: ', this.nrofplayersperteam);
    if (
      !playerIsPresent &&
      this.addplayer &&
      playersAdded < this.nrofplayersperteam
    ) {
      if (playersAdded == 0) {
        this.player1 = this.addplayer;
      }
      if (playersAdded == 1) {
        this.player2 = this.addplayer;
      }
      if (playersAdded == 2) {
        this.player3 = this.addplayer;
      }
      if (playersAdded == 3) {
        this.player4 = this.addplayer;
      }
      if (playersAdded == 4) {
        this.player5 = this.addplayer;
      }
      if (playersAdded == 5) {
        this.player6 = this.addplayer;
      }
    }

    const r = this.teamreserves.split(',');
    for (let i = 0; i < r.length; i++) {
      if (i == 0) {
        this.reserve1 = r[i];
      }
      if (i == 1) {
        this.reserve2 = r[i];
      }
      if (i == 2) {
        this.reserve3 = r[i];
      }
    }

    this.inputRemarks = this.remarks;

    // Get logs
    this.loadCompositionLogs();
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
:root {
  --ms-font-size: 0.8rem;
  --ms-line-height: 0.85;

  --ms-option-bg-selected: #4dabf7;
  --ms-option-bg-selected-pointed: #4dabf7;
  --ms-option-font-size: 0.8rem;
}
</style>

<style scoped>
form {
  height: 400px;
  font-size: 0.8rem;
}

.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

h3 {
  margin: 0.5rem 0;
  font-size: 1rem;
}

ul {
  list-style-type: none;
  padding-left: 0px;
}

.buttons {
  margin-top: 10px;
}

.players {
  display: flex;
  margin-bottom: 5px;
}

.playerlabel {
  width: 75px;
  padding-top: 4px;
}

.playerlabelmobile {
  width: 25px;
  padding-top: 4px;
}

.playerselection {
  width: 100%;
  padding-left: 5px;
}

.error {
  color: var(--var-error);
  font-weight: bold;
}

.boxed {
  overflow: scroll;
  overflow-x: hidden;
  height: 180px;
  margin-left: 5px;
}

textarea {
  width: 100%;
}

.logs {
  font-size: 10px;
}

.emailtext {
  font-size: 16px;
  font-weight: lighter;
  display: inline;
  padding: 3px;
}

.remarktext {
  font-size: 12px;
}
</style>
