const compositionObject = function(data) {
  const teamcomposition = data.teamcomposition
    .replace(/color:red/g, 'color:#fa5252')
    .replace(/color:green/g, 'color:#2f9e44');

  return {
    id: data.id,
    playDate: data.playdate,
    playTime: data.playtime,
    playId: data.playid,
    dateDescription: data.datedescription,
    weekDescription: data.weekdescription,
    division: data.division,
    nrOfPlayersPerTeam: data.nrofplayersperteam,
    startPlayerIndex: data.startplayerindex,
    captain: data.captain,
    meeting: data.ontmoeting,
    teamId: data.team_id,
    team: data.team,
    teamDescription: data.teamdescription,
    composition: teamcomposition,
    error: data.teamcompositionerror,
    members: data.members,
    reserves: data.reserves,
    intermediateScore: data.intermediatescore,
    score: data.score,
    won: data.won,
    homeout: data.homeout,
    isHome: data.ishome,
    address: data.address,
    frenoyUrl: data.frenoyurl,
    googleMapsUrl: data.googlemapsurl,
    remarks: data.remarks,
    driverId: data.driver_id,
    driver: data.driver,
    driverRemarks: data.driverremarks,
    confirmedMembers: data.confirmedmembers,
    confirmed: data.confirmed,
    denied: data.denied,
    canDrive: data.candrive,
    report: data.report,
    icalUrl: data.icalurl
  };
};

export default {
  async loadTeams(context) {
    let url = context.rootState.apiUrl + 'team_list.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token
      })
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het ophalen van de ploegen!'
      );
      throw error;
    }

    const teams = [];

    for (const key in responseData) {
      const team = {
        id: responseData[key].id,
        team: responseData[key].team,
        associationCode: responseData[key].association_code,
        division: responseData[key].division
      };
      teams.push(team);
    }

    // console.log(teams);

    context.commit('setTeams', teams);
  },
  async loadTeamCompositions(context, payload) {
    if (!payload.forceRefresh && !payload.id && !context.getters.shouldUpdate) {
      return;
    }

    let url = context.rootState.apiUrl + 'team_composition.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        ...(payload.id && { id: payload.id })
      })
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het ophalen van de ploegopstellingen!'
      );
      throw error;
    }

    let teamCompositions = [];
    if (!payload.id) {
      for (const key in responseData) {
        teamCompositions.push(compositionObject(responseData[key]));
      }

      context.commit('setFetchTimestamp');
    } else {
      teamCompositions = context.getters['teamCompositions'];

      for (const key in responseData) {
        const teamComposition = compositionObject(responseData[key]);
        const index = teamCompositions.findIndex(
          c => c.id === teamComposition.id
        );
        if (index >= 0) teamCompositions[index] = teamComposition;
        if (index < 0)
          teamCompositions.push(compositionObject(responseData[key]));
      }
    }

    // console.log(teamCompositions);
    context.commit('setTeamCompositions', teamCompositions);
  },
  async loadCompositionLogs(context, payload) {
    let url = context.rootState.apiUrl + 'team_composition_log.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        teamcalendar_id: payload.id
      })
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het ophalen van de opstellingslogs!'
      );
      throw error;
    }

    const compositionLogs = [];

    for (const key in responseData) {
      const compositionLog = {
        id: responseData[key].id,
        member: responseData[key].member,
        userName: responseData[key].username,
        logDate: responseData[key].logdate,
        modification: responseData[key].modification
      };
      compositionLogs.push(compositionLog);
    }

    // console.log(compositionLogs);

    context.commit('setCompositionLogs', compositionLogs);
  },
  async setDriver(context, payload) {
    let url = context.rootState.apiUrl + 'update_driver.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        id: payload.id,
        member_id: payload.member_id,
        remarks: payload.remarks
      })
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het aanpassen van de chauffeur!'
      );
      throw error;
    }

    const teamCompositions = context.getters['teamCompositions'];

    for (const key in responseData) {
      const teamComposition = compositionObject(responseData[key]);
      const index = teamCompositions.findIndex(
        c => c.id === teamComposition.id
      );
      if (index >= 0) teamCompositions[index] = teamComposition;
    }
  },
  async deleteDriver(context, payload) {
    let url = context.rootState.apiUrl + 'delete_driver.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        id: payload.id,
        member_id: payload.member_id
      })
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het verwijderen van de chauffeur!'
      );
      throw error;
    }

    const teamCompositions = context.getters['teamCompositions'];

    for (const key in responseData) {
      const teamComposition = compositionObject(responseData[key]);
      const index = teamCompositions.findIndex(
        c => c.id === teamComposition.id
      );
      if (index >= 0) teamCompositions[index] = teamComposition;
    }
  },
  async participateMeeting(context, payload) {
    let url = context.rootState.apiUrl + 'participate_meeting.php';
    // console.log(payload);
    const token = context.rootState.token;
    const canDrive = payload.canDrive ? 1 : 0;
    await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        id: payload.id,
        confirmationoption: payload.confirmationOption,
        candrive: canDrive,
        remarks: payload.remarks
      })
    }).then(response => {
      const responseData = response.json();

      if (!response.ok) {
        const error = new Error(
          responseData.message || 'Fout bij het doorgeven van je aanmelding!'
        );
        throw error;
      }

      const teamCompositions = context.getters['teamCompositions'];

      for (const key in responseData) {
        const teamComposition = compositionObject(responseData[key]);
        const index = teamCompositions.findIndex(
          c => c.id === teamComposition.id
        );
        if (index >= 0) teamCompositions[index] = teamComposition;
      }
    });
  },
  async denyMeeting(context, payload) {
    let url = context.rootState.apiUrl + 'deny_meeting.php';

    const token = context.rootState.token;

    // console.log(url);

    // const response =
    await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        id: payload.id,
        confirmationoption: payload.confirmationOption,
        remarks: payload.remarks
      })
    }).then(response => {
      if (!response.ok) {
        const error = new Error(
          responseData.message || 'Fout bij het doorgeven van je afmelding!'
        );
        throw error;
      }

      // console.log(response);
      const responseData = response.json();
      // console.log(responseData);

      const teamCompositions = context.getters['teamCompositions'];

      for (const key in responseData) {
        const teamComposition = compositionObject(responseData[key]);
        const index = teamCompositions.findIndex(
          c => c.id === teamComposition.id
        );
        if (index >= 0) teamCompositions[index] = teamComposition;
      }
    });
  },
  async setIntermediateScore(context, payload) {
    let url = context.rootState.apiUrl + 'set_intermediatescore.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        id: payload.id,
        intermediatescore: payload.intermediateScore
      })
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message ||
          'Fout bij het doorgeven van de tussentijdse score!'
      );
      throw error;
    }

    const teamCompositions = context.getters['teamCompositions'];

    for (const key in responseData) {
      const teamComposition = compositionObject(responseData[key]);
      const index = teamCompositions.findIndex(
        c => c.id === teamComposition.id
      );
      if (index >= 0) teamCompositions[index] = teamComposition;
    }
  },
  async loadAvailableTeamPlayers(context, payload) {
    let url = context.rootState.apiUrl + 'team_playerslist.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        id: payload.id
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message ||
          'Fout bij het ophalen van de beschikbare spelers!'
      );
      throw error;
    }

    const teamPlayers = [];

    for (const key in responseData) {
      const teamPlayer = {
        id: responseData[key].member_id,
        playerName: responseData[key].membername,
        listNumber: responseData[key].listnumber,
        indexNumber: responseData[key].indexnumber
      };
      teamPlayers.push(teamPlayer);
    }

    // console.log(teamPlayers);
    context.commit('setAvailableTeamPlayers', teamPlayers);
  },
  async setTeamComposition(context, payload) {
    // console.log(payload);

    let url = context.rootState.apiUrl + 'team_composition_update.php';

    const token = context.rootState.token;

    // Players
    const players = [];
    if (payload.player1) {
      players.push(payload.player1);
    }
    if (payload.player2) {
      players.push(payload.player2);
    }
    if (payload.player3) {
      players.push(payload.player3);
    }
    if (payload.player4) {
      players.push(payload.player4);
    }
    if (payload.player5) {
      players.push(payload.player5);
    }
    if (payload.player6) {
      players.push(payload.player6);
    }

    // Reserves
    const reserves = [];
    if (payload.reserve1) {
      reserves.push(payload.reserve1);
    }
    if (payload.reserve2) {
      reserves.push(payload.reserve2);
    }
    if (payload.reserve3) {
      reserves.push(payload.reserve3);
    }

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        teamcalendar_id: payload.id,
        players: players,
        reserves: reserves,
        remarks: payload.remarks
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het aanpassen van de ploegopstelling!'
      );
      throw error;
    }

    const teamCompositions = context.getters['teamCompositions'];

    for (const key in responseData) {
      const teamComposition = compositionObject(responseData[key]);
      const index = teamCompositions.findIndex(
        c => c.id === teamComposition.id
      );
      if (index >= 0) teamCompositions[index] = teamComposition;
    }
  },
  async sendTeamComposition(context, payload) {
    const token = context.rootState.token;
    let url = context.rootState.apiUrl + 'team_composition_mail.php';

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        teamcalendar_id: payload.id,
        teamplayers: 1,
        playingplayers: 1,
        reserves: 1
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het uitsturen van de e-mailberichten!'
      );
      throw error;
    }
  },
  async sendIcalEventFile(context, payload) {
    const token = context.rootState.token;
    let url = context.rootState.apiUrl + 'team_composition_icalmail.php';
    let params = {
      jwt: token
    };
    if (payload.teamId) {
      url = context.rootState.apiUrl + 'team_icalmail.php';
      params.team_id = payload.teamId;
    } else {
      params.teamcalendar_id = payload.teamcalendarId;
    }

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(params)
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message ||
          'Fout bij de aanvraag voor een e-mail met iCal-informatie!'
      );
      throw error;
    }
  }
};
