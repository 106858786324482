import { createStore } from 'vuex';
import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

import teamsModule from './modules/teams/index.js';
import membersModule from './modules/members/index.js';
import sterktelijstenModule from './modules/sterktelijsten/index.js';
import playermomentsModule from './modules/playermoments/index.js';
import presencesModule from './modules/presences/index.js';
import reservationsModule from './modules/reservations/index.js';
import trainingModule from './modules/training/index.js';
import sportscampsModule from './modules/sportscamps/index.js';
import statisticsModule from './modules/statistics/index.js';
import leaguesModule from './modules/leagues/index.js';

const store = createStore({
  modules: {
    teams: teamsModule,
    members: membersModule,
    sterktelijsten: sterktelijstenModule,
    playermoments: playermomentsModule,
    presences: presencesModule,
    reservations: reservationsModule,
    training: trainingModule,
    sportscamps: sportscampsModule,
    statistics: statisticsModule,
    leagues: leaguesModule
  },
  state() {
    return {
      apiUrl: '',
      appName: '',
      appDescription: '',
      passwordUrl: '',

      loginId: null,
      userId: null,
      clubName: null,
      clubCode: null,
      association: null,
      disciplines: [],
      token: null,
      firstName: null,
      lastName: null,
      email: null,
      memberId: null,
      memberIds: null,
      trainingModuleActive: 0,
      sportsCampModuleActive: 0,
      trainerId: null,
      trainerprofile: null,
      additionalTraineeSelection: null,
      frenoyUrl: null,
      frenoyPublicUrl: null,
      compositionsNeedRelease: null,
      myTeams: null,
      playerMoments: null,
      showPlayerMomentsInTheFuture: null,
      hasSportsCamp: null,
      composeAsCaptain: null,
      composeAnyTeam: null,
      availableContributionForms: [],
      contributionFormURL: null,
      homePage: 3,
      messageOfTheDay: null,
      teamCompositionModuleActive: null,
      hasLeague: null,
      leaguePlayerMomentInfo: false,
      enterAnyLeagueResult: null,
      isSubscribedForTheLeague: null,
      canUnsubscribeFromLeague: true,
      transportTypes: [],
      teamConfirmationOptions: [],

      didAutoLogout: false,
      isNavOpen: false,

      myMembers: [],

      gpdrExplicitAllowance: true,
      gdprMailings: true,
      gdprSmses: true,
      gdprBirthday: true,
      gdprPhoto: true,

      personalinformation: {
        lastName: null,
        firstName: null,
        address: null,
        zip: null,
        city: null,
        birthdate: null,
        telephone: null,
        mobile: null,
        email: null,
        ibanAccount: null,
        financialInformation: null,
        associationId: null,
        ageCategory: null,
        displayParentInformation: false,
        fatherLastName: null,
        fatherFirstName: null,
        fatherTelephone: null,
        fatherMobile: null,
        fatherEmail: null,
        motherLastName: null,
        motherFirstName: null,
        motherTelephone: null,
        motherMobile: null,
        motherEmail: null
      },

      frenoyResults: {
        ELOPoints: null,
        ELODate: null,
        RankingResults: null
      }
    };
  },
  mutations,
  actions,
  getters
});

export default store;
