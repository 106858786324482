import { createApp } from 'vue';

import router from './router.js';
import store from './store/index.js';
import App from './App.vue';
import BaseCard from './components/ui/BaseCard.vue';
import BaseButton from './components/ui/BaseButton.vue';
import BaseBadge from './components/ui/BaseBadge.vue';
import BaseSpinner from './components/ui/BaseSpinner.vue';
import BaseDialog from './components/ui/BaseDialog.vue';
import BasePopup from './components/ui/BasePopup.vue';
import BaseSearch from './components/ui/BaseSearch.vue';
import BaseCombo from './components/ui/BaseCombo.vue';
import BaseSelect from './components/ui/BaseSelect.vue';
import BaseTabs from './components/ui/BaseTabs.vue';
import BaseButtonSelect from './components/ui/BaseButtonSelect.vue';

import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import '@mdi/font/css/materialdesignicons.css';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const vuetify = createVuetify({
  components,
  directives,
});

const app = createApp(App);

app.use(router);
app.use(store);
app.use(vuetify);

app.component('base-card', BaseCard);
app.component('base-button', BaseButton);
app.component('base-badge', BaseBadge);
app.component('base-spinner', BaseSpinner);
app.component('base-dialog', BaseDialog);
app.component('base-popup', BasePopup);
app.component('base-search', BaseSearch);
app.component('base-combo', BaseCombo);
app.component('base-select', BaseSelect);
app.component('base-tabs', BaseTabs);
app.component('base-button-select', BaseButtonSelect);

app.mount('#app');
